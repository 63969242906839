import React, {MouseEvent, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Box, LinearProgress, Menu} from "@mui/material";
import {DataGrid, GridRowData, GridSortModel} from "@mui/x-data-grid";
import {
    EquipmentRequestListSortOrder,
    EquipmentRequestListSortOrderInitial
} from "store/reactiveVarsStores/eqipment-requests/equipmentRequestListSortOrder";
import {SportEquipmentListPagination} from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentListPagination";
import {EquipmentRelocationRequestEntity} from "graphql/graphQlApiHooks";
import {isValidNumber} from "helpers/isValidNumber";
import {SortDirectionEnum} from "store/reactiveVarsStores/spor-equipmnts/sort-direction.enum";
import {columns} from "./columns";
import MenuItems from "../MenuItems";

interface DialogsStates {
    equipmentDetails: boolean;
    equipmentRelocationHistory: boolean;
    requestForAdmission: boolean;
    acceptRelocation: boolean;
    rejectRelocation: boolean;
    relocateConfirmation: boolean;
    relocateCompletion: boolean;
    acceptTransportRequest: boolean;
    rejectTransportRequest: boolean;
}

interface Props {
    allCount: number;
    loading: boolean;
    relocateEquipments: EquipmentRelocationRequestEntity[];
    pagination: SportEquipmentListPagination;
    sortOrderModel: EquipmentRequestListSortOrder;

    actions: {
        setPagination: (pagination: SportEquipmentListPagination) => void;
        setOrder: (orderModel: EquipmentRequestListSortOrder) => void;
    };
}


// TODO:
//    добавить фильтрацию по полю при наведении на ячейку

export const List = (props: Props) => {
    const {relocateEquipments, pagination, allCount, loading, actions: {setPagination, setOrder}} = props;

    const navigate = useNavigate();


    const [contextRow, setContextRow] = useState<EquipmentRelocationRequestEntity | null>(null);

    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);

    const contextMenuHandler = (event: MouseEvent) => {
        event.preventDefault();
        const row = event.currentTarget;
        const sportEquipmentId = row.getAttribute("data-id");
        setContextRow(
            relocateEquipments.find((item) =>
                item.id === sportEquipmentId
            )
        );
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6
                }
                :
                null
        );
    };

    const contextMenuCloseHandler = () => {
        setContextMenu(null);
    };


    function sortModelChangeHandler(model: GridSortModel) {
        const sort = model.map((item) => {
            return {
                [item.field]: item.sort.toUpperCase() as SortDirectionEnum
            } as EquipmentRequestListSortOrder;
        });
        setOrder(sort?.[0] ? sort?.[0] : EquipmentRequestListSortOrderInitial);
    }

    return (
        <Box style={{height: "100%", width: "100%"}}>
            <DataGrid
                rows={[...relocateEquipments].sort((a, b) => {
                    const isValidA = isValidNumber(a.equipment.inventoryNumber);
                    const isValidB = isValidNumber(b.equipment.inventoryNumber);

                    // Если один из номеров некорректен, он должен идти первым
                    if (!isValidA && isValidB) {
                        return -1;
                    } else if (isValidA && !isValidB) {
                        return 1;
                    }

                })}
                columns={columns}
                getRowClassName={({id}) => (id === contextRow?.id && contextMenu !== null) ? "isOpenContextMenu" : ""}
                rowHeight={50}
                loading={loading}

                sx={{
                    "& .isOpenContextMenu": {
                        bgcolor: "action.hover"
                    }
                }}

                componentsProps={{
                    row: {
                        onContextMenu: contextMenuHandler,
                        style: {cursor: "context-menu"}
                    }
                }}
                components={{
                    LoadingOverlay: LinearProgress
                }}

                sortingMode='server'
                onSortModelChange={sortModelChangeHandler}

                disableSelectionOnClick={true}

                columnBuffer={columns.length}
                columnThreshold={columns.length}
                rowThreshold={pagination?.pageSize / 2}
                rowBuffer={pagination?.pageSize / 2}


                pagination
                paginationMode='server'
                rowCount={Math.round(allCount)}
                page={pagination?.page}
                pageSize={pagination?.pageSize}
                onPageChange={(page) => setPagination({...pagination, page: page})}
                onPageSizeChange={(pageSize) =>
                    setPagination({...pagination, pageSize: pageSize})
                }
                rowsPerPageOptions={[5, 10, 50, 100]}
            />

            <Menu
                open={contextMenu !== null}
                onClose={contextMenuCloseHandler}
                anchorReference='anchorPosition'
                anchorPosition={
                    contextMenu !== null
                        ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                        : undefined
                }
                elevation={22}
                sx={{
                    maxWidth: 300,
                    "& .MuiListItemText-root .MuiTypography-root": {
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "100%",
                        display: "flow-root"
                    }
                }}
            >
                {contextRow && <MenuItems
                    relocateEquipment={contextRow}
                />}
            </Menu>
        </Box>
    );
};