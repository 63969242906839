import React, {FC, useState} from 'react';
import {EquipmentRepairRequestEntity, SportEquipmentEntity} from "../../../../graphql/graphQlApiHooks";
import {DataGrid, GridRowData, GridSortModel} from "@mui/x-data-grid";
import {columns} from "./columns";
import {Box, Menu} from "@mui/material";
import {isValidNumber} from "../../../../helpers/isValidNumber";
import {
    RepairRequestsListPagination
} from "../../../../store/reactiveVarsStores/repair-requests/repairRequestsListPagination";
import {
    RepairRequestsListSortOrder
} from "../../../../store/reactiveVarsStores/repair-requests/repairRequestsListSortOrder";
import {SortDirectionEnum} from "../../../../store/reactiveVarsStores/spor-equipmnts/sort-direction.enum";
import {
    SportEquipmentListSortOrder
} from "../../../../store/reactiveVarsStores/spor-equipmnts/sportEquipmentListSortOrder";

interface Props {
    equipmentRepairRequest?: EquipmentRepairRequestEntity[]
    allCount?: number
    loading?: boolean

    pagination: RepairRequestsListPagination;
    sortOrderModel: RepairRequestsListSortOrder;

    actions: {
        setPagination: (pagination: RepairRequestsListPagination) => void;
        setOrder: (orderModel: RepairRequestsListSortOrder) => void;
    }
}


function MoreMenuItems(props: { onClose: () => void, equipmentRepairRequest: EquipmentRepairRequestEntity }) {
    return null;
}

const List: FC<Props> = (props: Props) => {
    const {
        equipmentRepairRequest,
        allCount,
        loading,
        pagination,
        sortOrderModel,
        actions: {
            setPagination,
            setOrder
        }
    } = props;

    const sortedEquipmentRepairRequest = [...equipmentRepairRequest || []]?.sort((a, b) => {
        if (a.sportEquipment?.inventoryNumber && b.sportEquipment?.inventoryNumber) {
            const isValidA = isValidNumber(a.sportEquipment?.inventoryNumber);
            const isValidB = isValidNumber(b.sportEquipment?.inventoryNumber);

            // Если один из номеров некорректен, он должен идти первым
            if (!isValidA && isValidB) {
                return -1;
            } else if (isValidA && !isValidB) {
                return 1;
            }
        }
        return 0
    })

    function sortModelChangeHandler(model: GridSortModel) {
        const sort = model.map((item) => {
            return {
                [item.field]: item.sort.toUpperCase() as SortDirectionEnum
            } as RepairRequestsListSortOrder;
        });
        setOrder(sort[0]);
    }

    const [contextRow, setContextRow] = useState<EquipmentRepairRequestEntity | null>(null);
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number;
        mouseY: number;
    } | null>(null);
    const contextMenuCloseHandler = () => {
        setContextMenu(null);
    };

    const doubleClickHandler = (id: string) => {
        //TODO вынести в action
        console.log('doubleClickHandler', id);
    };



    return (
        <Box style={{height: "100%", width: "100%"}}>
            <DataGrid
                loading={loading}
                rows={[...sortedEquipmentRepairRequest || []]}
                disableSelectionOnClick={true}

                sx={{
                    "& .isOpenContextMenu": {
                        bgcolor: "action.hover"
                    }
                }}

                onRowDoubleClick={(params: GridRowData) => doubleClickHandler(params.row.id)}

                columnBuffer={columns.length}
                columnThreshold={columns.length}
                rowThreshold={pagination?.pageSize / 2}
                rowBuffer={pagination?.pageSize / 2}

                columns={columns}
                getRowClassName={({ id }) => (id === contextRow?.id && contextMenu !== null) ? "isOpenContextMenu" : ""}
                rowsPerPageOptions={[5, 10, 50, 100]}
                rowHeight={50}

                sortingMode='server'
                onSortModelChange={sortModelChangeHandler}
                pagination
                onPageSizeChange={(pageSize) =>
                    setPagination({...pagination, pageSize: pageSize})
                }

            />

            <Menu
                open={contextMenu !== null}
                onClose={contextMenuCloseHandler}
                anchorReference='anchorPosition'
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
                elevation={22}
                sx={{
                    maxWidth: 300,
                    "& .MuiListItemText-root .MuiTypography-root": {
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "100%",
                        display: "flow-root"
                    }
                }}
            >
                {contextRow && <MoreMenuItems
                    equipmentRepairRequest={contextRow}
                    onClose={contextMenuCloseHandler}
                />}
            </Menu>

        </Box>
    );
};

export default List
