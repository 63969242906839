import React, {FC, useEffect} from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Tooltip
} from '@mui/material';
import {
    EquipmentRequestEntity, GetEquipmentRequestsQuery,
    useExportEquipmentRequestsMutation, useGetEquipmentRequestsLazyQuery,
    useGetEquipmentRequestsQuery
} from '../../../../graphql/graphQlApiHooks';
import {useStores} from '../../../../store';
import {useSnackbar} from 'notistack';
import {downloadFile} from '../../../../helpers/downloadFile';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {LoadingButton} from "@mui/lab";
import {SiMicrosoftexcel} from "react-icons/si";


/**
 * Кнопка экспорта заявок на оборудование.
 */
export const ExportEquipmentRequestsButton: FC = () => {
    // Заявки на оборудование
    const {equipmentRequestStore} = useStores();

    const [getEquipmentRequests] = useGetEquipmentRequestsLazyQuery();


    // Прочее
    const [exportRequests, {loading}] = useExportEquipmentRequestsMutation();
    const {enqueueSnackbar} = useSnackbar();

    // Получение текста для подсказки
    const getTooltipText = (count: number) => {
        const lastDigits = count.toString().slice(-2);
        return lastDigits.endsWith('1') && !lastDigits.endsWith('11')
            ? `из ${count} заявки`
            : `из ${count} заявок`;
    };
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const [open, setOpen] = React.useState(false);
    const options = ['Экспорт выбранных заявок', 'Экспорт всех заявок'];
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

    useEffect(() => {
        if (selectedIndex === 0) {
            getEquipmentRequests({
                variables: {
                    input: {
                        ...equipmentRequestStore?.input,
                        skip: 0,
                        take: 0
                    }
                }
            }).then((res) => {
                setSelectedIds(res?.data?.getEquipmentRequests?.equipmentRequests?.map((request) => request.id));
            })
        } else {
            getEquipmentRequests({
                variables: {
                    input: {
                        sportObjectIds: equipmentRequestStore?.input?.sportObjectIds,
                        equipmentTypeIds:[],
                        sportSubZoneIds:[],
                        sportZoneIds:[],
                        sportKindIds:[],
                        skip: 0,
                        take: 0
                    }
                }
            }).then((res) => {
                setSelectedIds(res.data?.getEquipmentRequests?.equipmentRequests?.map((request) => request.id));
            })
        }
    }, [selectedIndex, equipmentRequestStore]);

    const handleClick = () => {
        exportRequests({
            variables: {
                ids: selectedIds
            }
        })
            .then((res) => downloadFile(res.data.exportedFile))
            .catch((err) => enqueueSnackbar(`Ошибка экспорта файла: ${err?.message}`, {variant: 'error'}));
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (

        <Box>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <Tooltip
                    title={selectedIndex === 0 ?
                        equipmentRequestStore.equipmentRequest?.length
                            ? `Сгенерировать Excel файл ${getTooltipText(equipmentRequestStore?.equipmentRequest?.length)}`
                            : 'Не найдены заявки для экспорта, попробуйте сбросить фильтры' :
                        'Сгенерировать Excel файл из всех заявок'}
                >
                    <LoadingButton
                        onClick={handleClick}
                        startIcon={<SiMicrosoftexcel/>}
                        disabled={loading || (!equipmentRequestStore?.equipmentRequest?.length && selectedIndex === 0)}
                        loading={loading}
                        variant='contained'
                        size='small'
                    >{options[selectedIndex]}</LoadingButton>
                </Tooltip>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    variant='contained'
                    sx={{
                        padding: '0',
                    }}
                    onClick={handleToggle}
                >
                    <ArrowDropDownIcon/>
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            disabled={index === 2}
                                            selected={index === selectedIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};
