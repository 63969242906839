import React, { useEffect, useRef } from "react";
import { Card, IconButton, InputAdornment, OutlinedInputProps, Stack, TextField, Typography } from "@mui/material";
import {
  EquipmentRequestEntity,
  SportEquipmentEntity,
  TechnicalCondition,
  UpdateEquipmentRequestInput
} from "graphql/graphQlApiHooks";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import { TextFieldLabel } from "../../../../Equipments/components/Dialogs/views/TextFieldLabel";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";


interface Props {
  errors?: FormikErrors<any>;
  sportEquipment: SportEquipmentEntity;
  equipmentRequest: EquipmentRequestEntity;
  setSportEquipment: (values: React.SetStateAction<SportEquipmentEntity>) => void;
  isEditable?: boolean;
  touched?: FormikTouched<any>;
  handleBlur?: (e: any) => void;
  requestInput: UpdateEquipmentRequestInput;
  setRequestInput: (values: React.SetStateAction<UpdateEquipmentRequestInput>) => void;
}

export const SportEquipmentRequestSupplied = (props: Props) => {
  const {
    errors,
    sportEquipment,
    isEditable,
    setSportEquipment,
    touched,
    handleBlur,
    equipmentRequest,
    requestInput,
    setRequestInput
  } = props;


  const failureReasonRef = useRef(null);

  useEffect(() => {
    if (sportEquipment?.technicalCondition === TechnicalCondition.NotWorking && failureReasonRef?.current) {
      failureReasonRef?.current.focus();
    }
  }, [failureReasonRef, sportEquipment?.technicalCondition]);


  function onChangeCount(e: any) {
    const count = e.target.value;
    setRequestInput(prevState => ({ ...prevState, count }));

  }

  function onClickCountUp() {
    setRequestInput(prevState => ({ ...prevState, count: (Number(prevState.count) + 1) }));

  }

  function onClickCountDown() {
    if (Number(requestInput.count) > 1) {
      setRequestInput(prevState => ({ ...prevState, count: (Number(prevState.count) - 1) }));
    } else {
      setRequestInput(prevState => ({ ...prevState, count: 1 }));
    }
  }

  return (
    <>
      <Stack spacing={3}>

        <Card
          sx={{ p: 3 }}
          elevation={2}
        >

          <Stack spacing={3}>
            <Typography variant={"h5"}>
              Информация при приеме
            </Typography>
            <Stack
              direction={"row"}
              spacing={2}
              justifyItems={"space-between"}
            >

              <Typography variant={"caption"}>
                Количество запрошенного оборудования:
              </Typography>
              <Typography
                variant={"body1"}
                textAlign={"right"}
                fontWeight={"bold"}
              >
                {equipmentRequest.count}
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              spacing={2}
            >
              <Typography variant={"caption"}>
                Количество оборудования при приеме:
              </Typography>
              <TextField
                autoComplete='off'
                onBlur={handleBlur}
                size={"small"}
                sx={{ width: "140px", ".MuiInputBase-input": { textAlign: "center" } }}
                id='count'
                name='count'
                value={requestInput.count}
                onChange={onChangeCount}
                type={"number"}
                inputProps={{
                  min: 1,
                }}

                {
                  ...{
                    ...(touched?.count && errors?.count && { error: true }),
                    ...(touched?.count && errors?.count && { helperText: errors?.count as string })
                  }
                }
                InputProps={{
                  inputProps: { min: 1 },
                  endAdornment: <InputAdornment position='end'>
                    <IconButton
                      onClick={onClickCountUp}
                      edge='start'
                      size='small'
                    > <AddIcon fontSize='small' /> </IconButton>
                  </InputAdornment>,
                  startAdornment: <InputAdornment position='start'>
                    <IconButton
                      onClick={onClickCountDown}
                      edge='end'
                      size='small'
                    > <RemoveIcon fontSize='small' /></IconButton>
                  </InputAdornment>
                } as Partial<OutlinedInputProps>}
              />


            </Stack>

          </Stack>
        </Card>


      </Stack>
    </>
  );
};
