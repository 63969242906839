import {
	LoginResult,
	UserEntity,
	Scalars,
	LoginDocument,
	LoginUserInput,
	UpdateUserSettingsInput, UpdateUserSettingsDocument, UsersDocument, SportObjectEntity
} from "../../graphql/graphQlApiHooks";

import {autorun, makeAutoObservable, runInAction} from "mobx";
import RootStore, {client} from "../.";
import {isEqual, queryToMobxObservable} from "../../helpers";
import Settings from "../settings";
import { userVars } from "../reactiveVarsStores/auth/user";

const userStore = class {
	rootStore?: RootStore;
	currentUser?: any;
	user?: UserEntity;
	token?: Scalars['String'];
	isLoggedIn: Boolean = false;
	loginProgress: Boolean = false;
	loginError: Boolean = false;
	loginErrorMessage?: Scalars['String'];
	users?: UserEntity[]


	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this)

		let token = localStorage.getItem('user_token');
		let userLogin = localStorage.getItem('user_login');
		let userSportObjectsData = localStorage.getItem('user_sportObjects')
		let userSportObjects: string[]  = (userSportObjectsData !== 'undefined') ? JSON.parse(userSportObjectsData as string) : [];
		let isForSportObject = JSON.parse(localStorage.getItem('user_isForSportObject') as string);
		let userSettings = JSON.parse(localStorage.getItem('user_userSettings') as string);
		let user = JSON.parse(localStorage.getItem('user') as string);

		this.currentUser = undefined;

		if (!token)
			return;

		this.setToken(token)

		runInAction(() => {
			this.currentUser = {
				token: token,
				user: {} as UserEntity
			} as LoginResult;

			if (user) {
				userVars({ ...user })
			}

			if (userLogin) {
				this.currentUser.user.login = userLogin;
			}
			if (userSportObjects?.length) {
				this.currentUser.user.sportObjects = userSportObjects;
			}
			if (isForSportObject) {
				this.currentUser.user.isForSportObject = isForSportObject;
			}
			if (userSettings) {
				this.currentUser.user.userSettings = userSettings;
			}
		});

		autorun(() => {
			if (this.queryUsers?.current()) {
				let result: any = this.queryUsers.current()
				let users = result.users

				if (users && !isEqual(users, this.users)) {
					this.users = users;
				}
			}
		})

	}

	login(input: LoginUserInput) {
		this.setLoginProgress(true)
		this.clearLoginErrors()

		new Promise(() => {
			client.query({
				query: LoginDocument,
				variables: {input : {...input, sourceType: 'ACCOUNTING_SYSTEM'}},
			})
					.then((result: any) => {
						this.setUser(result.data.login)
					})
					.catch((error) => {
						this.setError(error)
					})
		})
	}

	setUser = (login: LoginResult) => {
		this.setLoginProgress(false)
		this.setCurrentUser(login)

		userVars({ ...login.user })

		if (!login) return;
		this.setToken(login.token)


		localStorage.setItem(`user`, JSON.stringify(login.user));

		for (let key in login.user) {
			if (key !== '__typename' && key !== 'sportObjects') {
				// @ts-ignore
				if (typeof login.user[key] === 'object') {
					// @ts-ignore
					localStorage.setItem(`user_${key}`, JSON.stringify(login.user[key]));
				} else {
					// @ts-ignore
					localStorage.setItem(`user_${key}`, login.user[key]);
				}
			}
		}

		localStorage.setItem('user_token', login.token);
		let userSportObjectsIds = login.user.sportObjects?.map((o: any) => o.id) as string[]
		localStorage.setItem('user_sportObjects', JSON.stringify(userSportObjectsIds as string[]));
		localStorage.setItem('user_logged_out', JSON.stringify(false));
	};

	clearUser() {
		this.setIsLoggedIn(false)
		this.setCurrentUser(undefined)
		this.setToken(undefined)
		this.rootStore?.sportObjectsStore.setCurrentIds([])
		localStorage.clear();
		localStorage.setItem('user_logged_out', JSON.stringify(true));
	}

	clearLoginErrors() {
		this.setLoginError(false)
		this.setLoginErrorMessage(undefined)
	}

	setError(error: any) {
		console.error('user Login error!', error)

		this.setLoginError(true)
		this.setLoginProgress(false)

		if (error.message === 'Wrong Login or password') {
			this.setLoginErrorMessage('Не верный логин или пароль');
		} else {
			this.setLoginErrorMessage('Что-то пошло не так, попробуйте войти позже.');
		}
	}

	setIsLoggedIn(state: boolean) {
		this.isLoggedIn = state
	}

	setLoginProgress(state: boolean) {
		this.loginProgress = state
	}

	setCurrentUser(login: LoginResult | undefined) {
		this.currentUser = login
	}

	setLoginError(state: boolean) {
		this.loginError = state
	}

	setLoginErrorMessage(message: string | undefined) {
		this.loginErrorMessage = message
	}

	setToken(token: string | undefined) {
		this.token = token
	}

	updateUserSettings = (input: UpdateUserSettingsInput) => client.mutate({
		mutation: UpdateUserSettingsDocument,
		variables: {input}
	})
			.then(() => {
				this.setUserSettings(input)
				this.rootStore?.setSnakeMessage('Ваши настройки обновлены!')
			})
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));


	setUserSettings(input: UpdateUserSettingsInput) {
		const userSettings = Object.assign({...this.currentUser.user.userSettings}, input)
		this.currentUser.user.userSettings = userSettings
		localStorage.setItem('user_userSettings', JSON.stringify(userSettings));
	}

	get queryUsers() {
		if (this?.currentUser) {
			return queryToMobxObservable(client.watchQuery({
				query: UsersDocument,
				pollInterval: Settings.pollIntervals.users,
				fetchPolicy:'network-only'
			}));
		}
		return undefined;
	}

	getCurrentDepartmentName(name: any) {
		if (!name) return;

		const departments = {
			'ADMINS': 'Администратор',
			'PLANNING_AND_ORGANIZATION_PAID_SERVICES': 'Планирование и организация платных услуг',
			'EQUIPMENT_ACCEPTANCE_AND_DIAGNOSTICS': 'Приемка и диагностика оборудования',
			'SPORT_OBJECT': 'Спортивный объект',
		}

		//@ts-ignore
		return departments[name]
	}

}

export default userStore;
