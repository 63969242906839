import {
	CreateEquipmentRequestDocument,
	CreateEquipmentRequestInput,
	DeleteEquipmentRequestDocument, EquipmentRequestAcceptIntoUseInput,
	EquipmentRequestEntity, EquipmentRequestsAcceptForProcessingDocument, EquipmentRequestsAcceptForProcessingMutation,
	EquipmentRequestStatusEnum,
	GetEquipmentRequestsDocument,
	SportObjectEntity,
	SportSubZoneEntity,
	SportZoneEntity,
	UpdateEquipmentRequestDocument,
	UpdateEquipmentRequestInput
} from "../../graphql/graphQlApiHooks";

import { autorun, makeAutoObservable, runInAction } from "mobx";
import RootStore, { client } from "..";
import { isEqual, queryToMobxObservable } from "../../helpers";
import Settings from "../settings";
import dayjs from "dayjs";

interface SortOrder {
	[key: string]: 'DESC' | 'ASC'
}

interface Filters {
	sportZoneIds: string[] | null;
	sportSubZoneIds: string[] | null;
	equipmentTypeIds: string[] | null;
	sportKindIds: string[] | null;
	name: string | null;
	description: string | null;
	requestStatus: EquipmentRequestStatusEnum | null;
	rejectReason: string | null;
	applicationDate: [Date | null, Date | null];
}

const equipmentRequestStore = class {
	rootStore: RootStore;
	error: any;
	loading: boolean = false;
	equipmentRequest: EquipmentRequestEntity[] = [];
	count: number = 0;
	page: number = 0;
	pageSize: number = 50;
	sortOrder: SortOrder | null = null;



	get statuses() {
		return [
			{id: EquipmentRequestStatusEnum.Submitted, name: 'На рассмотрении'},
			{id: EquipmentRequestStatusEnum.Pending, name: 'На рассмотрении'},
			{id: EquipmentRequestStatusEnum.Success, name: 'Ожидает отправления'},
			{id: EquipmentRequestStatusEnum.Supplied, name: 'Исполнена'},
			{id: EquipmentRequestStatusEnum.Rejected, name: 'Отклонена'},
			{id: EquipmentRequestStatusEnum.PassedToAno, name: 'Передана в АНО'},
			{id: EquipmentRequestStatusEnum.PassedToContractualService, name: 'Передана в контрактную cлужбу'},
		]
	};

	filters: Filters = {
		sportZoneIds: null,
		sportSubZoneIds: null,
		equipmentTypeIds: null,
		sportKindIds: null,
		name: null,
		description: null,
		requestStatus: null,
		rejectReason: null,
		applicationDate: [null, null],
	}

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this)

		autorun(() => {
			if (this.query?.current()) {
				let result: any = this.query.current();
				let count = result.getEquipmentRequests.count;
				result = result.getEquipmentRequests.equipmentRequests;

				if (!isEqual(result, this.equipmentRequest)) {
					this.equipmentRequest = result;
					this.count = count;
				}

				this.setLoading(false)
			}

			if (!this.filters.sportZoneIds && this.sportZones.length > 0) {
				this.setFilters({sportZoneIds: this.sportZones.map(val => val.id)})
			}

		})

	}
	get input():any{
		return {
			// sportObjectIds: this.filters?.sportObjectIds,
			sportObjectIds: this.rootStore?.sportObjectsStore?.currentIds,
			sportZoneIds: this.filters?.sportZoneIds,
			sportSubZoneIds: this.filters?.sportSubZoneIds,
			equipmentTypeIds: this.filters?.equipmentTypeIds,
			sportKindIds: this.filters?.sportKindIds,
			name: this.filters?.name,
			description: this.filters?.description,
			requestStatus: this.filters?.requestStatus,
			rejectReason: this.filters?.rejectReason,
			dateFrom: this.filters?.applicationDate?.[0] ? dayjs(this.filters?.applicationDate?.[0]).format('YYYY-MM-DD') : null,
			dateTo: this.filters?.applicationDate?.[1] ? dayjs(this.filters?.applicationDate?.[1]).format('YYYY-MM-DD') : null,
			skip: this.page * this.pageSize,
			take: this.pageSize,
			order: this.sortOrder
		}
	}
	get query() {
		if (this.rootStore?.userStore?.currentUser)
			return queryToMobxObservable(client.watchQuery({
				query: GetEquipmentRequestsDocument,
				variables: {
					input: this.input
				},
				pollInterval: Settings.pollIntervals.equipmentRequests,
			}));
		return undefined;
	}

	get sportZones(): SportZoneEntity[] {
		let sportObjectsIds = this.rootStore?.sportObjectsStore?.currentIds;
		let sportObjects = this.rootStore?.sportObjectsStore.sportObjects.filter((sportObject: SportObjectEntity) => sportObjectsIds.includes(sportObject.id));
		return [].concat.apply([], [...sportObjects.map((sportObject: SportObjectEntity) => sportObject.sportZones)])
	}

	get sportSubZones(): SportSubZoneEntity[] {

		if (this.filters.sportZoneIds) {
			let filterSportZones = this.sportZones.filter((zone: SportZoneEntity) => this.filters.sportZoneIds?.includes(zone.id)) || []
			return [...filterSportZones].map((zone: SportZoneEntity) => zone.sportSubZones).flat()
		}

		return [...this.sportZones].map(sz => sz.sportSubZones).flat()
	}

	get sportZoneIds(): string[] {
		return [...this.sportZones].map(zone => zone.id)
	}

	get sportSubZoneIds(): string[] {
		return this.sportSubZones.map(value => value.id)
	}

	setFilters(filter: any) {

		if (Object.values(filter)[0] === '' || !Object.values(filter)[0]) {
			const filterName = Object.keys(filter)[0];
			filter = (filterName !== 'applicationDate') ? {[filterName]: null} : {[filterName]: [null, null]}
		}

		runInAction(() => {
			this.filters = Object.assign(this.filters, filter)
		})

	}

	setSortOrder(sortOrder: SortOrder | null) {
		this.sortOrder = sortOrder
	}

	setLoading(loading: boolean) {
		this.loading = loading
	}

	setPage(page: number) {
		this.page = page
	}

	setPageSize(pageSize: number) {
		this.pageSize = pageSize
	}

	create = (input: CreateEquipmentRequestInput) => client.mutate({
		mutation: CreateEquipmentRequestDocument,
		variables: {input},
		refetchQueries: [{query: GetEquipmentRequestsDocument,
			variables: {
				input: this.input
			}}]
	})
			.then(() => this.rootStore?.setSnakeMessage('Новая заявка на оборудование успешно создана.'))
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

	update = (input: UpdateEquipmentRequestInput) => client.mutate({
		mutation: UpdateEquipmentRequestDocument,
		variables: {input},
		refetchQueries: [{query: GetEquipmentRequestsDocument,
		variables: {
			input: this.input
		}}]
	})
			.then(() => this.rootStore?.setSnakeMessage('Заявка на оборудование успешно обновлена.'))
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

	equipmentRequestsAcceptForProcessing = (inputs: EquipmentRequestAcceptIntoUseInput[] | EquipmentRequestAcceptIntoUseInput) => client.mutate({
		mutation: EquipmentRequestsAcceptForProcessingDocument,
		variables: {inputs},
		refetchQueries: [{query: GetEquipmentRequestsDocument,
		variables: {
			input: this.input
		}}]
	})
			.then(() => this.rootStore?.setSnakeMessage('Заявка на оборудование принята в работу.'))
			.catch((error: any) => this.rootStore?.setSnakeMessage('Не удалось принять заявку на оборудование в работу. ' + error.message, 'error'));

	delete = (id: string) => client.mutate({
		mutation: DeleteEquipmentRequestDocument,
		variables: {id},
		refetchQueries: [{query: GetEquipmentRequestsDocument,
			variables: {
				input: this.input
			}}]
	})
			.then(() => this.rootStore?.setSnakeMessage('Заявка на оборудование успешно удалена.'))
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

	getStatusName(status: string) {
		return this.statuses.find((stat: any) => stat.id === status)?.name
	}
}

export default equipmentRequestStore;
