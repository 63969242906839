import {
  DepartmentEnum,
  EquipmentRequestEntity,
  EquipmentRequestStatusEnum
} from "../../../../graphql/graphQlApiHooks";
import { GridRowData } from "@mui/x-data-grid";
import { useReactiveVar } from "@apollo/client";
import { userVars } from "../../../../store/reactiveVarsStores/auth/user";
import { Box, Button } from "@mui/material";
import React from "react";
import { observer } from "mobx-react";
import MoreMenuRenderCell from "./MoreMenuRenderCell";

interface RenderActionsCellProps {
  equipmentRequest: EquipmentRequestEntity;
  onSuccess: () => void;
  onReject: () => void;
  onSupplied: () => void;
  moreMenuProps: {
    row: GridRowData;
    handleEdit: (row: GridRowData) => void;
    handleDelete: (id: string) => void;
  }
}
const RenderActionsCell = observer(({equipmentRequest, onSuccess, onReject, onSupplied, moreMenuProps}: RenderActionsCellProps) => {
  const user = useReactiveVar(userVars);
  const isOnSportObject = user?.sportObjects?.some(so => so.id === equipmentRequest.sportObject.id) || false;
  const isEquipmentAcceptanceAndDiagnostics = user?.departments?.includes(DepartmentEnum.EquipmentAcceptanceAndDiagnostics) || false;

  const actions = () => {
    switch (equipmentRequest.requestStatus) {
      case EquipmentRequestStatusEnum.Submitted: {
        // Одобрять заявку может только сотрудник отдела приемки
        return (
          <>
            {isEquipmentAcceptanceAndDiagnostics ? <>
              <Button
                size={'small'}
                color={'error'}
                onClick={onReject}
              >
                Отклонить
              </Button>
              <Button
                size={'small'}
                variant={'contained'}
                onClick={onSuccess}
              >
                Одобрить
              </Button>
            </> : null}
            <MoreMenuRenderCell {...moreMenuProps} />
          </>
        );
      }
      case EquipmentRequestStatusEnum.PassedToAno:
      case EquipmentRequestStatusEnum.PassedToContractualService:
      {
        // Принимать оборудование может только сотрудник спорт объекта
        return (
          <>
            { (isOnSportObject || isEquipmentAcceptanceAndDiagnostics) && <Button
              size={'small'}
              variant={'contained'}
              onClick={onSupplied}
            >
              Принять оборудование
            </Button>}
          </>
        )
      }
      default: {
        return <MoreMenuRenderCell {...moreMenuProps} disabled/>
      }
    }

  };


  return (
    <Box
      alignItems={'center'}
      display={"flex"}
      sx={{
        "& .MuiButton-root": {
          mr: 2,
          py: .2,
          px: 1.5,
        }
      }}
    >
      {actions()}
    </Box>
  )
});

export default RenderActionsCell;