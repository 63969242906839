import { GridRowData } from "@mui/x-data-grid";
import React from "react";
import { BootstrapTooltip } from "../../../../components/BootstrapTooltip";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { observer } from "mobx-react";
import useStyles from "./styles";

const MoreMenuRenderCell = observer((props: { row: GridRowData, handleEdit: (row: GridRowData) => void, handleDelete: (id: string) => void, disabled?: boolean }) => {
  const classes = useStyles();
  const {row} = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClickMoreAreas = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreAreas = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <BootstrapTooltip title={'Дополнительные действия'}>
        <IconButton
          size={'medium'}
          onClick={handleClickMoreAreas}
          id={`more-button-${row.id}`}
          aria-expanded={openMenu ? 'true' : undefined}
          aria-controls="more-menu"
          aria-haspopup="true"
          disabled={props.disabled}
        >
          <MoreHorizIcon fontSize="small" sx={{
            fontSize: '18px !important'
          }}/>
        </IconButton>
      </BootstrapTooltip>

      <Menu
        id={`more-menu-${row.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={handleCloseMoreAreas}
        elevation={2}
        className={classes.moreMenu}

        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}

        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            handleCloseMoreAreas()
            props.handleEdit(row)
          }}
        >Изменить</MenuItem>
        <MenuItem
          onClick={() => {
            handleCloseMoreAreas()
            props.handleDelete(row.id as string)
          }}
        >Удалить</MenuItem>

      </Menu>

    </>);
});

export default MoreMenuRenderCell;