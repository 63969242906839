import React, { FC } from "react";
import { useSnackbar } from "notistack";
import { Box } from "@mui/system";
import { Alert, Button, Card, Checkbox, DialogActions, DialogContent, FormControlLabel } from "@mui/material";
import { declOfNum } from "../../../../../helpers/declination";
import { LoadingButton } from "@mui/lab";
import {
  EquipmentRequestAcceptIntoUseInput,
  useEquipmentRequestsAcceptForProcessingMutation
} from "../../../../../graphql/graphQlApiHooks";
import { useStores } from "../../../../../store";

interface Props {
  close: () => void;
  equipments: string[];

}

const SportEquipmentsTakeToWork: FC<Props> = (rest) => {
  const { close, equipments } = rest;
  const { enqueueSnackbar } = useSnackbar();
  const [equipmentRequestsAcceptForProcessing] = useEquipmentRequestsAcceptForProcessingMutation();
  const [isPurchasedWithMosEdoMoney, setIsPurchasedWithMosEdoMoney] = React.useState(true);

  const {equipmentRequestStore} = useStores();

  const handleSubmit = () => {
    const inputs = equipments.map((id) => ({
      id,
      isPurchasedWithMosEdoMoney
    })) as EquipmentRequestAcceptIntoUseInput[];

    equipmentRequestStore.equipmentRequestsAcceptForProcessing(inputs).then(() => {
      close();
    })
  };

  return (

    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 2,
        borderTop: "1px solid",
        borderColor: "divider"
      }}
    >

      <Alert severity='info'>
        <>
          {declOfNum(equipments.length, ["Выбрана", "Выбрано", "Выбрано"])} {" "}
          <b>{equipments.length}</b> {declOfNum(equipments.length, ["единица", "единицы", "единиц"])} оборудования,
          для приема в работу. <br />
        </>
      </Alert>
      <DialogContent
        dividers
        sx={{
          bgcolor: "background.default"
        }}
      >
        <Card sx={{ p: 3 }}>

          <FormControlLabel
            control={<Checkbox
              onChange={(event) => setIsPurchasedWithMosEdoMoney(event.target.checked)}
              value={isPurchasedWithMosEdoMoney}
              checked={isPurchasedWithMosEdoMoney}
            />}
            label='Закупается на деньги МСО' 

          />


        </Card>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          color='secondary'
          size='small'
        >
          Отмена
        </Button>

        <LoadingButton
          // loading={isSubmitting}
          size='small'
          onClick={() => handleSubmit()}
          variant='contained'
        >
          Принять в работу
        </LoadingButton>
      </DialogActions>
    </Box>
  );
};

export default SportEquipmentsTakeToWork;