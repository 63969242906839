import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  status: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderRadius: '50px',
    fontWight: 'bold',
    "& .MuiChip-icon": {
      color: 'inherit'
    },
    '&.submitted': {
      // backgroundColor: '#d2eefe',
      // color: '#01b1ff'
      backgroundColor: '#fdf7e7',
      color: '#ce6801'
    },
    '&.success': {
      backgroundColor: '#def1dd',
      color: '#2ca42c'
    },
    '&.supplied': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    },
    '&.rejected': {
      backgroundColor: '#ffd7d7',
      color: '#f73333'
    },
  },
  tableButton: {
    '& .MuiButton-label': {
      overflow: 'hidden',
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
    }
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(6),
  },
  moreMenu: {
    '& span': {
      padding: '8px 12px',
      display: 'block',
      color: theme.palette.text.secondary,
      fontSize: '13px'
    }
  }
}));

export default useStyles;