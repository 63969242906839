import React from "react";
import Settings from "./settings";

import UserStore from "./stores/user.store";
import SettingsStore from "./stores/settings.store";
import DictionariesStore from "./stores/dictionaries.store";
import SportObjectsStore from "./stores/sportObjects.store";
import SportZonesStore from "./stores/sportZones.store";
import SportSubZonesStore from "./stores/sportSubZones.store";
import EquipmentsStore from "./stores/equipments.store";
import ServiceJobsStore from "./stores/serviceJobs.store";
import ServicePeriodStore from "./stores/servicePeriod.store";
import EquipmentRequestStore from "./stores/equimentRequest.store";
import EquipmentRelocationRequestStore from "./stores/equipmentRelocationRequest.store";
import { makeAutoObservable } from "mobx";
import { ApolloClient, createHttpLink, DefaultOptions, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import GalleryStore from "./stores/gallery.store";
import { injectStores } from "@mobx-devtools/tools";
import SpecificationsStore from "./stores/specifications.store";
import { cache } from "cache";


const httpLink = createHttpLink({
	uri: `${Settings.server}/graphql`,
});

const authLink = setContext((_, {headers}) => {
	// get the authentication token from local storage if it exists
	const token = localStorage.getItem('user_token');

	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			Authorization: token ? `Bearer ${token}` : "",
		}
	}
});
const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: 'cache-first',
	},
	query: {
		fetchPolicy: 'cache-first',
	},
}


export const client = new ApolloClient({
	cache,
	link: authLink.concat(httpLink),
	defaultOptions: defaultOptions,
	connectToDevTools: true,
});

export default class RootStore {
	public userStore: any;
	public contractsStore: any;
	public sportObjectsStore: any;
	public settingsStore: any;
	public dictionariesStore: any;
	public sportZonesStore: any;
	public sportSubZonesStore: any;
	public equipmentsStore: any;
	public serviceJobsStore: any;
	public servicePeriodStore: any;
	public equipmentRequestStore: any;
	public equipmentRelocationRequestStore: any;
	public galleryStore: any;
	public specificationsStore: any;
	public snake: any = {
		msg: "",
		type: 'default'
	};


	constructor() {
		makeAutoObservable(this)
		this.userStore = new UserStore(this)

		this.settingsStore = new SettingsStore(this)
		this.dictionariesStore = new DictionariesStore(this)
		this.sportObjectsStore = new SportObjectsStore(this)
		this.sportZonesStore = new SportZonesStore(this)
		this.sportSubZonesStore = new SportSubZonesStore(this)
		this.equipmentsStore = new EquipmentsStore(this)
		this.serviceJobsStore = new ServiceJobsStore(this)
		this.servicePeriodStore = new ServicePeriodStore(this)
		this.equipmentRequestStore = new EquipmentRequestStore(this)
		this.equipmentRelocationRequestStore = new EquipmentRelocationRequestStore(this)
		this.galleryStore = new GalleryStore(this)
		this.specificationsStore = new SpecificationsStore(this)
	}

	setSnakeMessage = (msg: string, type: string = "default") => {
		this.snake = {msg, type}
	}

}

export const rootStore = new RootStore()

injectStores({
	rootStore
});

const StoresContext = React.createContext(rootStore);
export const useStores = () => React.useContext(StoresContext);

function persistCache(arg0: { cache: InMemoryCache; storage: Storage; }) {
    throw new Error('Function not implemented.');
}

