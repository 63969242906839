import {makeVar} from "@apollo/client";
import {DateRange} from "components/mui-daterange-picker";
import { DepartmentEnum } from "graphql/graphQlApiHooks";
import {EquipmentRelocationRequestStatusEnum} from "../../stores/equipment-relocation-request-status.enum";


export interface EquipmentRequestsFilters {
    name?: string;
    inventoryNumber?: string;

    equipmentTypeIds?: string[];
    sportKindIds?: string[];

    createdAt?: DateRange;

    sourceSportObjectIds?: string[];
    targetSportObjectIds?: string[];

    statuses?: (keyof typeof EquipmentRelocationRequestStatusEnum)[]
}

const initialFilters: EquipmentRequestsFilters = {
    name: null,
    inventoryNumber: null,

    equipmentTypeIds: null,
    sportKindIds: null,

    sourceSportObjectIds: null,
    targetSportObjectIds: null,

    createdAt: null,

    statuses: localStorage.getItem('user_departments')?.includes(DepartmentEnum.EquipmentAcceptanceAndDiagnostics) ? ['RequestSubmitted'] : Object.keys(EquipmentRelocationRequestStatusEnum)
        .filter((val) => val !== 'RequestSubmitted') as (keyof typeof EquipmentRelocationRequestStatusEnum)[]
}

export const equipmentRequestsFiltersVar = makeVar<EquipmentRequestsFilters>(initialFilters);

const resetFilters = () => {
    equipmentRequestsFiltersVar(initialFilters);
}

const setFilters = (filters: EquipmentRequestsFilters) => {
    equipmentRequestsFiltersVar(filters);
}

const setFilter = (filter: keyof EquipmentRequestsFilters, value: any) => {
    equipmentRequestsFiltersVar({
        ...equipmentRequestsFiltersVar(),
        [filter]: value
    });
}

const getFilters = () => {
    return equipmentRequestsFiltersVar();
}

const getFilter = (filter: keyof EquipmentRequestsFilters) => {
    return equipmentRequestsFiltersVar()[filter];
}

export const useEquipmentRequestsFilters = () => {
    return {
        setFilters,
        setFilter,
        getFilters,
        getFilter,
        resetFilters
    }
}