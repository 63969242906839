import {FC, useEffect} from 'react';
import Page from "components/Page";
import {Box} from "@mui/material";
import {Filters} from "./Main/Filters";
import PageContent from "components/PageContext";
import {Outlet} from "react-router-dom";
import PageTitle from 'components/PageTitle';
import {List} from "./Main/List";
import {
    EquipmentRelocationRequestEntity,
    GetRelocationRequestsInput,
    useGetRelocationRequestsLazyQuery
} from "graphql/graphQlApiHooks";
import {equipmentRequestsFiltersVar} from 'store/reactiveVarsStores/eqipment-requests/filtersVar';
import {useReactiveVar} from "@apollo/client";
import {
    equipmentRequestListSortOrderVar
} from "store/reactiveVarsStores/eqipment-requests/equipmentRequestListSortOrder";
import {
    equipmentRequestListPaginationVar
} from "store/reactiveVarsStores/eqipment-requests/equipmentRequestListPagination";
import {EquipmentRelocationRequestStatusEnum} from "store/stores/equipment-relocation-request-status.enum";
import {sportObjectsVars} from "../../../store/reactiveVarsStores/sport-objects/sportObjects";


export const EquipmentRelocate: FC = () => {
    const pageTitle = "МСО.Объявления";
    const filterValues = useReactiveVar(equipmentRequestsFiltersVar);
    const pagination = useReactiveVar(equipmentRequestListPaginationVar);
    const equipmentRequestListSortOrder = useReactiveVar(equipmentRequestListSortOrderVar);
    const sportObjects = useReactiveVar(sportObjectsVars);

    const [getRelocationRequests, {data, loading, error}] = useGetRelocationRequestsLazyQuery();

    useEffect(() => {
            const {createdAt, statuses, sourceSportObjectIds, targetSportObjectIds, ...filters} = filterValues;

            if (sportObjects.sportObjectList.length === 0) {
                return;
            }

            if (!sourceSportObjectIds) {
                equipmentRequestsFiltersVar({
                    ...filterValues,
                    sourceSportObjectIds: sportObjects.sportObjectList.map((sportObject) => sportObject.id),
                });

                return;
            }

            getRelocationRequests({
                variables: {
                    input: {
                        ...filters,

                        ...createdAt && {
                            dateFrom: createdAt?.startDate.toISOString(),
                            dateTo: createdAt?.endDate.toISOString()
                        },

                        sourceSportObjectIds: sourceSportObjectIds,
                        targetSportObjectIds: targetSportObjectIds,

                        statuses: statuses?.map((status) => EquipmentRelocationRequestStatusEnum[status]),


                        skip: pagination.page * pagination.pageSize,
                        take: pagination.pageSize,
                        order: equipmentRequestListSortOrder
                    } as GetRelocationRequestsInput
                }
            });

        }, [pagination, equipmentRequestListSortOrder, filterValues, sportObjects]
    );


    return (
        <Page title={pageTitle}>
            <Box
                display={"flex"}
                px={5}
                pt={4}
                pb={3}
                justifyContent={"space-between"}
                component={"header"}
                className="page__header"
            >
                <PageTitle title={pageTitle} count={data?.getRelocationRequests?.allCount}/>

            </Box>
            <Filters/>

            <PageContent>
                <List
                    data={data?.getRelocationRequests?.relocationRequests as EquipmentRelocationRequestEntity[]}
                    allCount={data?.getRelocationRequests?.allCount}
                    loading={loading}
                    error={error}
                />
                {<Outlet/>}
            </PageContent>
        </Page>
    );
}
