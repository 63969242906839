import React, {FC} from "react";
import {List as EquipmentRelocationRequestList} from "./List";
import {EquipmentRelocationRequestEntity} from "graphql/graphQlApiHooks";
import {SportEquipmentListPagination} from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentListPagination";
import {ApolloError, useReactiveVar} from "@apollo/client";
import {SportEquipmentListSortOrder} from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentListSortOrder";
import {Alert, Card} from "@mui/material";
import {
    equipmentRequestListSortOrderVar,
    useEquipmentRequestListSortOrderVar
} from "store/reactiveVarsStores/eqipment-requests/equipmentRequestListSortOrder";
import {
    equipmentRequestListPaginationVar,
    useEquipmentRequestListPaginationVar
} from "store/reactiveVarsStores/eqipment-requests/equipmentRequestListPagination";


interface props {
    data?: EquipmentRelocationRequestEntity[]
    loading?: boolean
    error?: ApolloError
    allCount?: number
}

export const List: FC<props> = ({data, error, loading, allCount}) => {
    const {setPagination} = useEquipmentRequestListPaginationVar();
    const {setOrder} = useEquipmentRequestListSortOrderVar();
    const pagination = useReactiveVar(equipmentRequestListPaginationVar);
    const sortOrderModel = useReactiveVar(equipmentRequestListSortOrderVar);


    if (error) return (
        <Card sx={{m: 2}}>
            <Alert severity="error">
                Возникла ошибка
                <pre style={{whiteSpace: 'pre-wrap'}}>
                    {JSON.stringify(error, null, 2)}
                </pre>
            </Alert>
        </Card>
    );


    return (
        <>
            <EquipmentRelocationRequestList
                relocateEquipments={data as EquipmentRelocationRequestEntity[] || []}

                pagination={pagination as SportEquipmentListPagination}
                sortOrderModel={sortOrderModel as SportEquipmentListSortOrder}


                allCount={allCount || 0}

                loading={loading}

                actions={{
                    setPagination,
                    setOrder
                }}
            />
        </>
    );
};