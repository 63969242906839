import React from 'react';
import {Box} from '@mui/material';
import EquipmentRequestTable from "./Table";
import Page from "components/Page";
import {useStores} from "../../../store";
import {observer} from "mobx-react-lite";
import PageTitle from "../../../components/PageTitle";
import PageContent from "../../../components/PageContext";
import {ExportEquipmentRequestsButton} from "./ExportEquipmentRequestsButton";


const EquipmentRequestEntity = observer(() => {
    const {equipmentRequestStore} = useStores();

    const pageTitle = "Заявки на оборудование";
    return (
        <Page title={pageTitle}>

            <Box
                display={"flex"}
                px={5}
                pt={4}
                pb={3}
                justifyContent={"space-between"}
                component={"header"}
                className="page__header"
            >
                <PageTitle title={pageTitle} count={equipmentRequestStore?.count}/>

                <ExportEquipmentRequestsButton/>
            </Box>

            <PageContent>
                <EquipmentRequestTable/>
            </PageContent>
        </Page>
    )
});

export default EquipmentRequestEntity;
