import React, {useEffect, useState} from "react";
import {
    Badge,
    Box,
    Button,
    CardHeader,
    Chip,
    DialogActions,
    DialogContent,
    DialogContentText,
    Fab,
    Fade,
    IconButton,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography
} from "@mui/material";
import {
    DataGrid,
    GridCellParams,
    GridCellValue,
    GridColumns,
    GridRowData,
    GridRowId,
    GridSortItem,
    GridSortModel
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import MuiDataGridStyles from "components/MuiDataGridStyles";
import {
    DepartmentEnum,
    EquipmentRequestEntity,
    EquipmentRequestStatusEnum,
    SportObjectEntity
} from "../../../../graphql/graphQlApiHooks";
import {EquipmentRequestCreateDialog} from "../Dialogs/Add";
import {EquipmentRequestEditDialog} from "../Dialogs/Edit";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import {useStores} from "../../../../store";
import {observer} from "mobx-react-lite";
import {styled} from "@mui/styles";
import clsx from "clsx";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import Dialog from "components/Dialogs/Dialog";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Tooltiper from "components/Tooltiper";
import {useReactiveVar} from "@apollo/client";
import {userVars} from "../../../../store/reactiveVarsStores/auth/user";
import {Footer} from "./Footer";
import {useDialog} from "../../../../providers/DialogProvider";
import SportEquipmentsTakeToWork from "./Footer/SportEquipmentsTakeToWork";
import useStyles from "./styles";
import CustomToolbar from "./CustomToolbar";
import RenderActionsCell from "./RenderActionsCell";
import SuccessDialog from "./SuccessDialog";
import AddSportEquipment from "./Dialogs/Add";
import RejectReasonDialog from "./RejectReasonDialog";
import DateViewer from "components/DateViewer";


const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} children={props.children}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(1, 2),
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 260,
        maxHeight: 220,
        position: 'relative',
        overflow: 'hidden',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

const TooltipDescriptionRenderCell = observer((props: {
    params: GridCellParams;
    openDescriptionDialog: (params: EquipmentRequestEntity) => void;
}) => (
    <HtmlTooltip
        TransitionComponent={Fade}
        TransitionProps={{timeout: 600}}
        disableFocusListener
        title={
            (props.params.row.description) ? <React.Fragment>
                <Typography
                    sx={{whiteSpace: 'nowrap', mb: 1}}
                    variant={'h6'}
                    color="inherit"
                >Дополнгительная информация</Typography>
                <Box
                    sx={{
                        textOverflow: "ellipsis",
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden'
                    }}
                >
                    {props.params.row.description}
                </Box>

                <Box display={'flex'}>
                    <Button
                        size={'small'}
                        onClick={() => props.openDescriptionDialog(props.params.row as EquipmentRequestEntity)}
                        sx={{marginLeft: 'auto', py: .5, px: 1, mt: 1}}
                    >Подробнее...</Button>
                </Box>

            </React.Fragment> : 'Дополнгительная информация'

        }
    >
        <IconButton
            color="inherit"
            component="span"
            disabled={!props.params.row.description}
            onClick={() => props.openDescriptionDialog(props.params.row as EquipmentRequestEntity)}
        >
            <Badge
                color="primary"
                variant="dot"
                invisible={!props.params.row.description}
            >
                <MessageOutlinedIcon
                    sx={{
                        opacity: .5,
                        transition: 'all .6s',
                        fontSize: '18px !important'
                    }}
                />
            </Badge>
        </IconButton>
    </HtmlTooltip>
));

const TooltipRejectionRenderCell = observer((props: {
    params: GridCellParams;
    openRejectionDialog: (params: EquipmentRequestEntity) => void;
}) => (
    <HtmlTooltip
        TransitionComponent={Fade}
        TransitionProps={{timeout: 600}}
        disableFocusListener
        title={
            (props.params.row.rejectReason) ? <React.Fragment>
                <Typography
                    sx={{whiteSpace: 'nowrap'}}
                    variant={'h6'}
                    color="inherit"
                >Причина отказа по заявке</Typography>
                <Box
                    sx={{
                        textOverflow: "ellipsis",
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden'
                    }}
                >
                    {props.params.row.rejectReason}
                </Box>

                <Box display={'flex'}>
                    <Button
                        onClick={() => props.openRejectionDialog(props.params.row as EquipmentRequestEntity)}
                        sx={{marginLeft: 'auto', py: 1, px: 2}}
                    >Подробнее...</Button>
                </Box>

            </React.Fragment> : 'Причина отказа по заявке'
        }
    >

        <IconButton
            color="inherit"
            component="span"
            disabled={!props.params.row.rejectReason}
            onClick={() => props.openRejectionDialog(props.params.row as EquipmentRequestEntity)}
        >
            <Badge
                color="primary"
                variant="dot"
                invisible={!props.params.row.rejectReason}
            >
                <MessageOutlinedIcon
                    sx={{
                        opacity: .5,
                        transition: 'all .6s',
                        fontSize: '18px !important'
                    }}
                />
            </Badge>
        </IconButton>
    </HtmlTooltip>
));


const EquipmentRequestTable = observer(() => {
    const classes = useStyles();
    const tableClasses = MuiDataGridStyles();
    const user = useReactiveVar(userVars);

    const [openAddEquipmentRequestItem, setOpenAddEquipmentRequestItem] = React.useState(false);
    const [openEditEquipmentRequestDialog, setOpenEditEquipmentRequestDialog] = React.useState(false);
    const [openDeleteEquipmentRequestDialog, setOpenDeleteEquipmentRequestDialog] = React.useState(false);

    const [editEquipmentRequest, setEditEquipmentRequest] = React.useState<GridRowData | null>(null)
    const [deleteEquipmentRequestId, setDeleteEquipmentRequestId] = React.useState<string | null>(null)

    const [rows, setRows] = React.useState([]);

    const [openDescriptionDialog, setOpenDescriptionDialog] = React.useState(false);
    const [openRejectionDialog, setOpenRejectionDialog] = React.useState(false);
    const [descriptionDialogContent, setDescriptionDialogContent] = React.useState('');
    const [descriptionRejectionContent, setDescriptionRejectionContent] = React.useState('');


    const {equipmentRequestStore, sportObjectsStore} = useStores();

    useEffect(() => {
        setRows([...equipmentRequestStore.equipmentRequest] as never[])
    }, [equipmentRequestStore.equipmentRequest, sportObjectsStore.currentIds]);

    function openEditDialogClick(row: GridRowData) {
        setEditEquipmentRequest(row)
        setOpenEditEquipmentRequestDialog(true)
    }

    function openDeleteDialogClick(id: string) {
        setDeleteEquipmentRequestId(id)
        setOpenDeleteEquipmentRequestDialog(true)
    }

    function editEquipmentRequestCloseHandle(val: boolean) {
        setOpenEditEquipmentRequestDialog(val)
    }

    function deleteEquipmentRequestCloseHandle(val: boolean) {
        setOpenDeleteEquipmentRequestDialog(val)
    }

    function deleteEquipmentRequestConfirmHandle(id: string) {
        equipmentRequestStore.delete(id)
    }

    const getStatusClass = (status: string) => {
        switch (status) {
            case EquipmentRequestStatusEnum.Submitted:
                return 'submitted'
            case EquipmentRequestStatusEnum.Success:
            case EquipmentRequestStatusEnum.PassedToContractualService:
            case EquipmentRequestStatusEnum.PassedToAno:
                return 'success'
            case EquipmentRequestStatusEnum.Supplied:
                return 'supplied'
            case EquipmentRequestStatusEnum.Rejected:
                return 'rejected'
            default:
                return ''
        }
    };
    const getStatusIcon = (status: string) => {
        switch (status) {
            case EquipmentRequestStatusEnum.Submitted:
                return <AccessTimeOutlinedIcon fontSize={"small"}/>
            case EquipmentRequestStatusEnum.Success:
            case EquipmentRequestStatusEnum.PassedToContractualService:
            case EquipmentRequestStatusEnum.PassedToAno:
                return <DoneIcon fontSize={"small"}/>
            case EquipmentRequestStatusEnum.Supplied:
                return <DoneAllIcon fontSize={"small"}/>
            case EquipmentRequestStatusEnum.Rejected:
                return <ThumbDownAltOutlinedIcon fontSize={"small"}/>
            default:
                return <ThumbDownAltOutlinedIcon fontSize={"small"}/>
        }
    };


    function handleOpenRejectionDialog(equipmentRequest: EquipmentRequestEntity) {
        setOpenRejectionDialog(true)
        setDescriptionRejectionContent(equipmentRequest?.rejectReason || '')
    }

    function handleOpenDescriptionDialog(equipmentRequest: EquipmentRequestEntity) {
        setOpenDescriptionDialog(true)
        setDescriptionDialogContent(equipmentRequest?.description || '')
    }

    const columns: GridColumns = [
        {
            field: 'request.name',
            headerName: 'Название необходимого оборудования',
            renderCell: (params) => {
                return (
                    <CardHeader
                        sx={{
                            p: 0,
                            maxWidth: '100%',
                            '& .MuiCardHeader-content': {
                                overflow: 'hidden',
                            },
                            '& .MuiTypography-root': {
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '100%',
                                overflow: 'hidden',
                            },
                        }}
                        title={
                            <Tooltiper
                                text={`${params.row.name.charAt(0).toUpperCase()}${params.row.name.slice(1)}`}
                                props={{
                                    variant: 'h6'
                                }}/>
                        }
                    />
                );
            },
            flex: 2,
            editable: false,
            disableColumnMenu: true,
        },
        {
            field: 'sportObject.name',
            headerName: 'Спортобъект',
            renderCell: (params) => {
                return (
                    <Tooltiper
                        text={`${params.row.sportObject.name.charAt(0).toUpperCase()}${params.row.sportObject.name.slice(1)}`}/>
                )
            },
            sortComparator: ((v1: GridCellValue, v2: GridCellValue) => {
                const a: SportObjectEntity = v1 as SportObjectEntity, b: SportObjectEntity = v2 as SportObjectEntity;
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            }),
            flex: 2,
            editable: false,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'request.count', headerName: 'Количество оборудования',
            flex: .5,
            renderCell: (params) => {
                return (
                    <Typography variant={'h6'}>
                        {params.row.count}
                    </Typography>
                );
            },
            editable: false,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'request.createdAt', headerName: 'Дата подачи заявки',
            type: 'date',
            renderCell: (params) => {
                const createdAt = params.row.createdAt
                return (
                    <Box>
                        <DateViewer date={createdAt}/>
                    </Box>
                );
            },
            minWidth: 120,
            disableColumnMenu: true,
            align: 'center',
            editable: false,
            headerAlign: 'center'
        },
        {
            field: 'request.sportKind', headerName: 'Вид спорта',
            renderCell: (params) => {
                const name = (params.row?.sportKindObject?.name) ? params.row.sportKindObject.name : (params.row?.sportKind) ? params.row.sportKind : ''
                return (
                    <>
                        <Tooltiper
                            text={`${name?.charAt(0)?.toUpperCase()}${name?.slice(1)}`}/>
                    </>
                );
            },
            flex: 2,
            editable: false,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'request.requestStatus', headerName: 'Статус заявки',
            renderCell: (params) => {
                return (

                    <Chip
                        size={'small'}
                        className={clsx(classes.status, getStatusClass(params.row.requestStatus))}
                        icon={getStatusIcon(params.row.requestStatus)}
                        label={<Tooltiper
                            text={equipmentRequestStore.getStatusName(params.row.requestStatus)}
                        />}
                    />

                );
            },
            flex: 1.2,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            disableColumnMenu: true,
        },
        {
            field: 'request.rejectReason', headerName: 'Причина отказа по заявке',
            renderCell: (params) => {
                return (
                    <TooltipRejectionRenderCell
                        params={params}
                        openRejectionDialog={(params: EquipmentRequestEntity) => handleOpenRejectionDialog(params)}
                    />
                );
            },
            sortable: false,
            flex: 1,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            disableColumnMenu: true,
        },
        {
            field: 'request.description', headerName: 'Дополнительная информация по заявке',
            renderCell: (params) => {
                return (
                    <TooltipDescriptionRenderCell
                        params={params}
                        openDescriptionDialog={(params: EquipmentRequestEntity) => handleOpenDescriptionDialog(params)}
                    />
                );
            },
            sortable: false,
            flex: 1,
            editable: false,
            hideSortIcons: true,
            disableColumnMenu: true,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'actions', headerName: 'Действия',
            renderCell: (params) => {
                return <RenderActionsCell
                    equipmentRequest={params.row}
                    onSuccess={() => {
                        onSuccessDialogHandler(params.row)
                    }}
                    onReject={() => {
                        onRejectReasonDialogHandler(params.row)
                    }}
                    onSupplied={() => {
                        onSuccessSuppliedDialogHandler(params.row)
                    }}
                    moreMenuProps={{
                        row: params.row,
                        handleEdit: openEditDialogClick,
                        handleDelete: openDeleteDialogClick
                    }}
                />
            },
            disableColumnMenu: true,
            flex: 2.4,
            minWidth: 300,
            editable: false,
            sortable: false,
            hideSortIcons: true,
            align: 'right',
            headerAlign: 'center'
        },
    ];


    const handleCloseDescriptionDialog = () => {
        setOpenDescriptionDialog(false)
    };

    const handleCloseRejectionDialog = () => {
        setOpenRejectionDialog(false)
    };

    const handleSortModelChange = (newModel: GridSortModel) => {
        const sortModel = newModel[0] as GridSortItem
        const sortOrder = sortModel?.field ? {[sortModel?.field]: sortModel?.sort?.toUpperCase()} : null

        equipmentRequestStore.setLoading(true);
        equipmentRequestStore.setSortOrder(sortOrder)
    };

    const [selected, setSelected] = useState<GridRowId[]>([]);

    const [dialog, closeDialog] = useDialog();
    const [successDialog, closeSuccessDialog] = useDialog();
    const [successSuppliedDialog, closeSuccessSuppliedDialog] = useDialog();
    const [rejectReasonDialog, closeRejectReasonDialog] = useDialog();

    const onTakeToWorkHandler = () => {
        dialog({
            onClose: closeDialog,
            title: "Приём оборудования в работу",
            children: <>
                <SportEquipmentsTakeToWork
                    equipments={
                        selected.map((id) => {
                            const row = rows.find((row) => row.id === id) as EquipmentRequestEntity;
                            if (row.requestStatus === EquipmentRequestStatusEnum.Submitted) {
                                return row.id
                            } else {
                                return null
                            }
                        }).filter((id) => id !== null) as string[]
                    }
                    close={() => {
                        setSelected([]);
                        closeDialog();
                    }}
                />
            </>
        });
    };


    const onSuccessDialogHandler = (equipment: EquipmentRequestEntity) => {
        successDialog({
            onClose: closeSuccessDialog,
            title: "Приём оборудования в работу",
            maxWidth: "xl",
            children: <>
                <SuccessDialog
                    equipment={equipment}
                    close={() => {
                        closeDialog();
                    }}
                />
            </>
        });
    };


    const onSuccessSuppliedDialogHandler = (equipment: EquipmentRequestEntity) => {
        successSuppliedDialog({
            onClose: closeSuccessSuppliedDialog,
            title: "Подтверждение приёма нового оборудования",
            maxWidth: "lg",
            scroll: "paper",
            children: <>
                <AddSportEquipment
                    equipment={equipment}
                    close={() => {
                        closeDialog();
                    }}
                />
            </>
        });
    };

    const onRejectReasonDialogHandler = (equipment: EquipmentRequestEntity) => {
        rejectReasonDialog({
            onClose: closeRejectReasonDialog,
            title: "Отклонение заявки на новое оборудование",
            maxWidth: "lg",
            children: <>
                <RejectReasonDialog
                    equipment={equipment}
                    close={() => {
                        closeDialog();
                    }}
                />
            </>
        });
    };

    return (
        <>
            <Box style={{height: "100%", width: "100%"}}>
                <DataGrid
                    // components={{Footer: () => <div/>}}
                    rows={rows}
                    columns={columns}
                    className={tableClasses.root}
                    rowHeight={60}
                    onRowDoubleClick={(params) => openEditDialogClick(params.row)}
                    loading={equipmentRequestStore.loading}

                    columnBuffer={10}
                    columnThreshold={10}
                    density={'standard'}
                    rowThreshold={3}
                    rowBuffer={5}
                    checkboxSelection={
                        user.departments.includes(DepartmentEnum.EquipmentAcceptanceAndDiagnostics)
                    }
                    disableSelectionOnClick={true}
                    selectionModel={selected}
                    onSelectionModelChange={(newSelection: any) => {
                        setSelected(newSelection);
                    }}

                    sx={{
                        "& .isOpenContextMenu": {
                            bgcolor: "action.hover"
                        }
                    }}
                    pagination
                    rowCount={equipmentRequestStore.count}
                    paginationMode="server"
                    page={equipmentRequestStore.page}
                    pageSize={equipmentRequestStore.pageSize}
                    onPageChange={(page) => equipmentRequestStore.setPage(page)}
                    onPageSizeChange={(pageSize) =>
                        equipmentRequestStore.setPageSize(pageSize)
                    }
                    rowsPerPageOptions={[5, 10, 50, 100]}

                    sortingMode="server"
                    onSortModelChange={handleSortModelChange}
                    components={{
                        Toolbar: CustomToolbar,
                        Footer: Footer
                    }}
                    componentsProps={{
                        footer: {
                            selected,
                            selectedAvailable: selected.map((id) => {
                                const row = rows.find((row) => row.id === id) as EquipmentRequestEntity;
                                if (row.requestStatus === EquipmentRequestStatusEnum.Submitted) {
                                    return row.id
                                } else {
                                    return null
                                }
                            }).filter((id) => id !== null).length,
                            onTakeToWorkHandler: onTakeToWorkHandler,
                            isDisabled: selected.map((id) => {
                                const row = rows.find((row) => row.id === id) as EquipmentRequestEntity;
                                if (row.requestStatus === EquipmentRequestStatusEnum.Submitted) {
                                    return row.id
                                } else {
                                    return null
                                }
                            }).filter((id) => id !== null).length === 0
                        },
                    }}
                />
            </Box>

            {/* Создавать заявки может только сотрудник спорт объекта */}
            {((user?.departments?.includes(DepartmentEnum.SportObject) && user?.sportObjects?.length)
                || user?.departments?.includes(DepartmentEnum.EquipmentAcceptanceAndDiagnostics)) && <Fab
                color="primary"
                aria-label="add"
                className={classes.fab}
                onClick={() => setOpenAddEquipmentRequestItem(true)}
            >
                <AddIcon/>
            </Fab>}

            <EquipmentRequestCreateDialog
                open={openAddEquipmentRequestItem}
                close={setOpenAddEquipmentRequestItem}
            />


            <DeleteDialog
                title={'Удаление заявки на обслуживание'}
                open={openDeleteEquipmentRequestDialog}
                close={deleteEquipmentRequestCloseHandle}
                confirm={(id: string) => deleteEquipmentRequestConfirmHandle(id)}
                id={deleteEquipmentRequestId}
            />

            {editEquipmentRequest === null ? null :
                <EquipmentRequestEditDialog
                    open={openEditEquipmentRequestDialog}
                    close={editEquipmentRequestCloseHandle}
                    row={editEquipmentRequest as EquipmentRequestEntity}
                />}

            <Dialog
                open={openDescriptionDialog}
                onClose={handleCloseDescriptionDialog}
                props={{
                    "aria-labelledby": "alert-dialog-title",
                    "aria-describedby": "alert-dialog-description"
                }}
                title='Дополнительная информация по заявке'
                actions={
                    <Button
                        onClick={handleCloseDescriptionDialog}
                        autoFocus
                    >
                        Закрыть
                    </Button>
                }
            >
                <DialogContentText id="alert-dialog-description">
                    {`${descriptionDialogContent?.charAt(0).toUpperCase()}${descriptionDialogContent?.slice(1)}`}
                </DialogContentText>
            </Dialog>

            <Dialog
                open={openRejectionDialog}
                onClose={handleCloseRejectionDialog}
                title="Причина отказа по заявке"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {descriptionRejectionContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseRejectionDialog}
                        autoFocus
                    >
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
});

export default EquipmentRequestTable;
