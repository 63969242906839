import {autorun, makeAutoObservable, runInAction, toJS} from "mobx"
import {
    GetAllSportObjectsDocument,
    SportObjectEntity, SportZoneEntity,
} from "../../graphql/graphQlApiHooks";
import RootStore from '../.';
import {isEqual, queryToMobxObservable, sortByCreateAt} from "../../helpers";
import Settings from "../settings";
import {client} from "../.";

const SportObjects = class {
    public rootStore?: RootStore;
    public current?: SportObjectEntity;
    public currentIds: string[] = [];
    public sportObjects: SportObjectEntity[] = [];
    public allSportObjects: SportObjectEntity[] = [];
    public loading: boolean = true
    public error?: any
    public count: number = 0
    // public userSportObjects: string[] | null = null;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this)
        autorun(()=>{
            if (this.querySportObjects?.current() ){
                let result: any = this.querySportObjects.current()
                result = result.getAllSportObjects.slice().sort(sortByCreateAt);

                let userSportObjects: SportObjectEntity[];
                if (this.userSportObjects !== null && this.userSportObjects.length > 0) {
                    let userSportObjectsIds: string[] = this.userSportObjects;
                    userSportObjects =  [...result].filter(({id}) => userSportObjectsIds.includes(id))
                } else {
                    userSportObjects = result
                }


                if ( !isEqual(result, toJS(this.allSportObjects)) ) {
                    this.allSportObjects = result
                }

                if ( !isEqual(userSportObjects, toJS(this.sportObjects)) ) {
                    runInAction(()=>{
                        this.sportObjects = userSportObjects;
                        this.count = this.sportObjects.length;
                        this.setLoading(false)
                    })

                    this.rootStore?.settingsStore.setSportObjects(userSportObjects);

                    if (this.sportObjects.length > 0 && !this.current && !localStorage.getItem('sportObjectId')) {
                        this.setCurrent(this.sportObjects[0].id)
                    }
                    if (localStorage.getItem('sportObjectId')) {
                        this.setCurrent(localStorage.getItem('sportObjectId') as string)
                    }
                    if (this.currentIds.length === 0 && !JSON.parse(localStorage.getItem('sportObjectIds') as string) ) {
                        if (this.sportObjects){
                            const sportObjectIds = [...this.sportObjects].map((obj:SportObjectEntity) => obj.id)
                            this.setCurrentIds(sportObjectIds)
                        }
                    }
                    if (JSON.parse(localStorage.getItem('sportObjectIds') as string)){
                        this.setCurrentIds(JSON.parse(localStorage.getItem('sportObjectIds') as string))
                    }
                }
                this.setLoading(false)
            }

        })
    }
    setLoading(loading: boolean){
        this.loading = loading
    }
    get userSportObjects(){
        const user_sportObjectsData = localStorage.getItem('user_sportObjects') as string
        return (user_sportObjectsData !== 'undefined') ?  JSON.parse(localStorage.getItem('user_sportObjects') as string) : []
    }

    get querySportObjects() {
        if ( this.rootStore?.userStore?.currentUser )
            return queryToMobxObservable(client.watchQuery({
                query: GetAllSportObjectsDocument,
                pollInterval: Settings.pollIntervals.allSportObjects,
            }));
        return undefined;
    }

    public setCurrent = async (sportObjectId: string) => {
        autorun(() => {
            this.rootStore?.sportZonesStore.fetchSportZones(sportObjectId)
        });

        runInAction(() =>  this.current = this.sportObjects.find((obj:SportObjectEntity) => obj.id === sportObjectId));

        localStorage.setItem('sportObjectId', sportObjectId)
    };

    public setSportObjects = (sportObjects: SportObjectEntity[]) => {
        this.sportObjects = sportObjects;
    };

    public setCurrentIds = (ids: string[]) => {
        runInAction(() => this.currentIds = ids )
        localStorage.setItem('sportObjectIds', JSON.stringify(ids))

        let sportObjects = this.rootStore?.sportObjectsStore.sportObjects.filter((sportObject: SportObjectEntity) => ids.includes(sportObject.id));
        const sportZoneIds = [].concat.apply([], [...sportObjects.map((sportObject: SportObjectEntity) => sportObject.sportZones)]).map((sportZone: SportZoneEntity) => sportZone.id)
        this?.rootStore?.equipmentsStore.setFilters({sportZoneIds: sportZoneIds})
        this?.rootStore?.serviceJobsStore.setFilters({sportZoneIds: sportZoneIds})
        this?.rootStore?.equipmentRequestStore.setFilters({sportZoneIds: sportZoneIds})

    };


};


export default SportObjects;
