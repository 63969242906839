import { useStores } from "../../../../store";
import FiltersPanel, { FiltersType } from "../../../../components/FiltersPanel";
import FilterAutocomplete from "../../../../components/FiltersPanel/FilterComponents/FilterAutocomplete";
import {
  EquipmentTypeDictionaryEntity,
  SportKindDictionaryEntity,
  SportSubZoneEntity
} from "../../../../graphql/graphQlApiHooks";
import FilterSearch from "../../../../components/FiltersPanel/FilterComponents/FilterSearch";
import FilterDateRange from "../../../../components/FiltersPanel/FilterComponents/FilterDateRange";
import dayjs from "dayjs";
import { GridToolbarContainer } from "@mui/x-data-grid";
import React from "react";
import { observer } from "mobx-react";

const CustomToolbar = observer(() => {
  const {equipmentRequestStore, dictionariesStore} = useStores();

  const filters: FiltersType[] = [
    {
      name: 'sportZoneIds',
      title: "Объекты спорта",
      removable: false,
      filterComponent: (props: any) => <FilterAutocomplete
        values={equipmentRequestStore.sportZones}
        selected={equipmentRequestStore.filters.sportZoneIds}
        filterResult={(result: SportSubZoneEntity[]) => equipmentRequestStore.setFilters({sportZoneIds: result.map(val => val.id)})}
        {...props}
      />,
      chipDescription: () => {
        const sportZoneIds = equipmentRequestStore.filters.sportZoneIds
        return (sportZoneIds !== null) ? `: ${sportZoneIds.length}/${equipmentRequestStore.sportZones.length}` : ''
      }
    },
    {
      name: 'sportSubZoneIds',
      title: "Спортзоны",
      removable: false,
      filterComponent: (props: any) => <FilterAutocomplete
        values={equipmentRequestStore.sportSubZones}
        selected={equipmentRequestStore.filters.sportSubZoneIds}
        filterResult={(result: SportSubZoneEntity[]) => equipmentRequestStore.setFilters({sportSubZoneIds: result.map(val => val.id)})}
        {...props}
      />,
      chipDescription: () => {
        const sportSubZoneIds = equipmentRequestStore.filters.sportSubZoneIds
        return (sportSubZoneIds !== null) ? `: ${sportSubZoneIds.length}/${equipmentRequestStore.sportSubZones.length}` : `: 0/${equipmentRequestStore.sportSubZones.length}`
      }
    },
    {
      name: 'equipmentTypeIds',
      title: 'Тип оборудования',
      filterComponent: (props: any) => <FilterAutocomplete
        values={dictionariesStore.equipmentTypeDictionary as EquipmentTypeDictionaryEntity[]}
        selected={equipmentRequestStore.filters.equipmentTypeIds}
        filterResult={(result: EquipmentTypeDictionaryEntity[]) => equipmentRequestStore.setFilters({equipmentTypeIds: result.map(val => val.id)})}
        {...props}
      />,
      chipDescription: () => {
        const filter = equipmentRequestStore.filters.equipmentTypeIds;
        return (filter !== null) ? `: ${filter.length}/${dictionariesStore.equipmentTypeDictionary.length}` : `: 0/${dictionariesStore.equipmentTypeDictionary.length}`
      },
      clearFilter: () => {
        equipmentRequestStore.setFilters({equipmentTypeIds: null})
      }
    },
    {
      name: 'sportKindIds',
      title: 'Вид спорта',
      filterComponent: (props: any) => <FilterAutocomplete
        values={dictionariesStore.sportKindDictionary as SportKindDictionaryEntity[]}
        selected={equipmentRequestStore.filters.sportKindIds}
        filterResult={(result: SportKindDictionaryEntity[]) => equipmentRequestStore.setFilters({sportKindIds: result.map(val => val.id)})}
        {...props}
      />,
      chipDescription: () => {
        const filter = equipmentRequestStore.filters.sportKindIds;
        return (filter !== null) ? `: ${filter.length}/${dictionariesStore.sportKindDictionary.length}` : `: 0/${dictionariesStore.sportKindDictionary.length}`
      },
      clearFilter: () => {
        equipmentRequestStore.setFilters({sportKindIds: null})
      }
    },
    {
      name: 'statuses',
      title: 'Статус заявки',
      filterComponent: (props: any) => <FilterAutocomplete
        values={equipmentRequestStore.statuses}
        selected={equipmentRequestStore.filters.requestStatus}
        filterResult={(result: any[]) => equipmentRequestStore.setFilters({requestStatus: result.map(val => val.id)})}
        {...props}
      />,
      chipDescription: () => {
        const filter = equipmentRequestStore.filters.requestStatus;
        return (filter !== null) ? `: ${filter.length}/${equipmentRequestStore.statuses.length}` : `: 0/${equipmentRequestStore.statuses.length}`
      },
      clearFilter: () => {
        equipmentRequestStore.setFilters({requestStatus: null})
      }
    },
    {
      name: 'name',
      title: 'Название оборудования',
      filterComponent: (props: any) => <FilterSearch
        value={equipmentRequestStore.filters.name}
        filterResult={(result: string) => equipmentRequestStore.setFilters({name: result})}
        {...props}
      />,
      chipDescription: () => {
        return (equipmentRequestStore.filters.name !== null && equipmentRequestStore.filters.name !== '') ? ` содержит ${equipmentRequestStore.filters.name}` : ''
      },
      clearFilter: () => {
        equipmentRequestStore.setFilters({name: null})
      }
    },
    {
      name: 'description',
      title: 'Описание',
      filterComponent: (props: any) => <FilterSearch
        value={equipmentRequestStore.filters.description}
        filterResult={(result: string) => equipmentRequestStore.setFilters({description: result})}
        {...props}
      />,
      chipDescription: () => {
        const description = equipmentRequestStore.filters.description;
        return (description !== null && description !== '') ? ` содержит ${description}` : ''
      },
      clearFilter: () => {
        equipmentRequestStore.setFilters({description: null})
      }
    },
    // requestStatus
    // rejectReason
    {
      name: 'applicationDate',
      title: 'Дата заявки',
      filterComponent: (props: any) => <FilterDateRange
        value={equipmentRequestStore.filters.applicationDate || [null, null]}
        filterResult={(result) => {
          equipmentRequestStore.setFilters({applicationDate: result})
        }}
        {...props}
      />,
      chipDescription: () => {
        const applicationDate = equipmentRequestStore.filters.applicationDate;
        if (applicationDate?.[0] !== null) {
          if (!dayjs(applicationDate?.[0]).isSame(applicationDate?.[1]) && applicationDate?.[1]) {
            return ` С ${dayjs(applicationDate?.[0]).format('ll')} по ${dayjs(applicationDate?.[1]).format('ll')}`
          } else {
            return ` ${dayjs(applicationDate?.[0]).format('ll')}`
          }
        } else {
          return ''
        }
      },
      clearFilter: () => {
        equipmentRequestStore.setFilters({applicationDate: [null, null]})
      }
    }
  ];

  function getFiltersSelected(filters: any) {
    let result = [];
    for (let [key, value] of Object.entries(equipmentRequestStore.filters)) {
      const filter = filters.find((item: any) => item.name === key);
      const isNullsArray = Boolean(Array.isArray(value) && value.filter((item: any) => item === null).length === 2)
      const isEmpty = Boolean(value == null || isNullsArray)
      if (!isEmpty || filter?.removable === false)
        result.push(filter)
    }
    return result;
  }


  return (
    <GridToolbarContainer>
      <FiltersPanel store={equipmentRequestStore} filters={filters} selected={getFiltersSelected(filters)}/>
      {/*getFiltersSelected(filters)*/}
    </GridToolbarContainer>
  )
});

export default CustomToolbar;