import React, { useState } from "react";
import UploaderService from "services/UplodaService";
import { Root } from "../SportEquipmentPhotos/PhotoGrid";
import { UploadPhotoButton as UploadDocumentsButton } from "../SportEquipmentPhotos/PhotoGrid/UploadPhotoButton";
import { ISportEquipmentDocuments } from "../../dialogs";
import { Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { PhotoThumbnail, ThumbnailsUploaderButton } from "../SportEquipmentPhotos/styled";
import Iconify from "components/Iconify";
import Settings from "store/settings";
import { BootstrapTooltip } from "../../../../../../../components/BootstrapTooltip";

interface Props {
  id: string;
  documents: ISportEquipmentDocuments;
  setDocuments: (value: ISportEquipmentDocuments) => void;
  isEditable?: boolean;
}

export const OtherDocumentsGrid = (props: Props) => {
  const {
    id,
    documents,
    setDocuments,
    isEditable
  } = props;

  const [progress, setProgress] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const uploadInput = React.createRef<HTMLInputElement>();

  const onUploadChange: React.FormEventHandler<HTMLInputElement> = (event) => {
    setLoading(true);
    setProgress(0);
    UploaderService.uploadFiles(event, {
      onUploadProgress: (progressEvent: any) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);

        if (percent < 100) {
          setProgress(percent);
        }
      }
    })
      .then((res) => {
          if (res) {
            setDocuments({
              ...documents,
              otherDocuments: [...documents?.otherDocuments || [], [...res]].flat()
            });

          }
        }
      )
      .finally(() => {
        setProgress(100);
        setLoading(false);
      });
  };

  const clearInputHandler = () => {
    if (uploadInput?.current) uploadInput.current.value = "";
  };
  return (
    <>
      <Root
        container
        spacing={2}
        className={"documents-grid"}
        marginTop={"0 !important"}
        marginLeft={"-16px !important"}
      >

        <UploadDocumentsButton
          buttonId={id}
          onClearInput={clearInputHandler}
          loading={loading}
          progress={progress}
          icon={"icon-park-outline:file-addition-one"}
          primaryText={"Загрузить документ"}
          disabled={!isEditable}
        />

        {documents?.otherDocuments?.map((document) => (
          <Grid item lg={1.5} xs={3} key={document.id}>
            <PhotoThumbnail>
              <ThumbnailsUploaderButton
                className={"file"}
                component={"div"}
                disableRipple={true}
                sx={{
                  width: "100%"
                }}
              >
                <Stack
                  mt={1}
                  {...{
                    alignItems: "center",
                    justifyContent: "center",
                    width: "95%"
                  }}
                >

                  <Typography
                    variant={"subtitle1"}
                    color={"inherit"}
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "100%",
                      textAlign: "center",
                      lineHeight: "initial",
                      fontSize: "14px",
                      fontWeight: "bold"
                    }}
                  >
                    {document.name}
                  </Typography>

                  <Stack direction={"row"} spacing={2} sx={{ mt: 1 }}>
                    <BootstrapTooltip title={`Скачать ${document.name}`}>
                      <Button
                        component={"a"}
                        href={`${Settings.server}${document.url}`}
                        size={"small"}
                        fullWidth
                        color={"inherit"}
                        sx={{
                          maxWidth: "auto",
                          minWidth: "auto",
                          fontSize: 16
                        }}
                      >
                        <Iconify
                          icon={"icon-park-outline:download"}
                        />
                      </Button>
                    </BootstrapTooltip>

                    <BootstrapTooltip title={`Удалить ${document.name}`}>
                      <Button
                        component={"div"}
                        disabled={!isEditable}
                        onClick={() => {
                          setDocuments({
                            ...documents,
                            otherDocuments: [...documents?.otherDocuments].filter(item => item?.id !== document?.id)
                          });
                        }}
                        size={"small"}
                        sx={{
                          maxWidth: "auto",
                          minWidth: "auto",
                          fontSize: 16
                        }}

                        color={"secondary"}
                      >
                        <Iconify
                          icon={"fluent:delete-12-regular"}
                        />
                      </Button>
                    </BootstrapTooltip>
                  </Stack>


                </Stack>


              </ThumbnailsUploaderButton>
            </PhotoThumbnail>
          </Grid>
        ))}
      </Root>

      <input
        id={`button-upload-${id}`}
        name={`button-upload-${id}`}
        style={{ display: "none" }}
        type="file"
        accept="documents/*"
        onInput={onUploadChange}
        ref={uploadInput}
        multiple
      />
    </>

  );
};
