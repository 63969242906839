import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Card, DialogActions, DialogContent } from "@mui/material";
import {
  GetSportEquipmentsDocument,
  SportEquipmentEntity,
  useChangeSportObjectMutation
} from "graphql/graphQlApiHooks";


import { FormikHelpers, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";

import { LoadingButton } from "@mui/lab";
import { declOfNum } from "helpers/declination";
import { SportEquipmentLocation } from "../../Dialogs/views/SportEquipmentLocation";
import {
  useGetSportEquipmentInputVars
} from "../../../../../../store/reactiveVarsStores/spor-equipmnts/getSportEquipmentsInput";


interface ChangeSportObjectValues {
  sportEquipmentIds: string[];
  sportObjectId: string;
  sportSubZoneId: string;
  sportZoneId: string;
}

interface Props {
  close: () => void;
  equipments: string[];
}

export const SportEquipmentsRelocate = (rest: Props) => {
  const { close, equipments } = rest;
  const { enqueueSnackbar } = useSnackbar();
  const { getInput } = useGetSportEquipmentInputVars();

  const [sportEquipment, setSportEquipment] = useState<SportEquipmentEntity | null>(null);

  const [changeSportObject] = useChangeSportObjectMutation();

  const ChangeSportObjectSchema = Yup.object().shape({
    sportObjectId: Yup.string().required("Выберите спортобъект").nullable(),
    sportZoneId: Yup.string().required("Выберите объект спорта").nullable(),
    sportSubZoneId: Yup.string().required("Выберите спортзону").nullable()
  });

  const formik = useFormik({
    initialValues: {
      sportEquipmentIds: equipments,
      sportObjectId: "",
      sportZoneId: "",
      sportSubZoneId: ""
    } as ChangeSportObjectValues,
    validationSchema: ChangeSportObjectSchema,
    onSubmit: (
      values: ChangeSportObjectValues,
      { setSubmitting }: FormikHelpers<ChangeSportObjectValues>
    ) => {
      changeSportObject({
        variables: {
          input: {
            sportEquipmentIds: equipments,
            toSportObjectId: values.sportObjectId,
            toSportZoneId: values.sportZoneId,
            toSportSubZoneId: values.sportSubZoneId
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GetSportEquipmentsDocument,
            fetchPolicy: "network-only",
            variables: {
              input: getInput()
            }
          }
        ]
      })
        .then((res) => {
          close();
          enqueueSnackbar(
            `Обрудование успешно перемещено.`
          );
        })
        .catch(({ message }) => {
          enqueueSnackbar(
            message.replace("Unexpected error value: ", "").replace(/"/g, ""),
            {
              variant: "error"
            }
          );

        })
        .finally(() => setSubmitting(false));
    }
  });

  const { isSubmitting, handleSubmit, touched, errors, setValues } = formik;

  useEffect(() => {
    if (sportEquipment) {
      setValues(prevState => ({
        ...prevState,
        ...(sportEquipment?.sportObject?.id && { sportObjectId: sportEquipment?.sportObject.id }),
        ...(sportEquipment?.sportSubZone?.id && { sportSubZoneId: sportEquipment?.sportSubZone.id }),
        ...(sportEquipment?.sportZone?.id && { sportZoneId: sportEquipment?.sportZone.id })
      } as ChangeSportObjectValues));
    }
  }, [sportEquipment]);

  return (
    <FormikProvider value={formik}>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        p: 2,
        borderTop: "1px solid",
        borderColor: "divider"
      }}>

        <Alert severity="info">
          <>
            {declOfNum(equipments.length, ["Выбрана", "Выбрано", "Выбрано"])}
            <b>{equipments.length}</b> {declOfNum(equipments.length, ["единица", "единицы", "единиц"])} оборудования,
            для перемещения. <br />
            Укажите <b>куда хотите переместить</b> выбранное оборудование.
          </>
        </Alert>

      </Box>

      <DialogContent
        dividers
        sx={{
          bgcolor: "background.default"
        }}
      >
        <Card sx={{ p: 3 }}>
          <SportEquipmentLocation
            vertical
            sportEquipment={sportEquipment}
            setSportEquipment={setSportEquipment}
          />
        </Card>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={close}
          color="secondary"
          size="small"
        >
          Отмена
        </Button>

        <LoadingButton
          loading={isSubmitting}
          size="small"
          onClick={() => handleSubmit()}
          variant="contained"
        >
          Переместить
        </LoadingButton>
      </DialogActions>
    </FormikProvider>
  )
    ;
};
