import {Filter, FiltersToolbar} from "../../../components/FiltersToolbar";
import React, {useMemo} from "react";
import {SportEquipmentListFilters} from "../../../store/reactiveVarsStores/spor-equipmnts/sportEquipmentListFilters";
import {EquipmentRepairRequestFilterInput, EquipmentRepairRequestStatusEnum} from "../../../graphql/graphQlApiHooks";
import {useReactiveVar} from "@apollo/client";
import {sportObjectsVars} from "../../../store/reactiveVarsStores/sport-objects/sportObjects";
import {
    equipmentRepairRequestsFiltersVar,
    useEquipmentRepairRequestsFiltersVar
} from "../../../store/reactiveVarsStores/repair-requests/filters";
import Autocomplete from "../../../components/FiltersToolbar/Autocomplete";
import {getLabelStatusRepairRequest} from "./List/TagStatusRepairRequest";

const Filters = () => {

    const {
        filteredSportZoneList,
        filteredSportSubZoneList
    } = useReactiveVar(sportObjectsVars);
    const filterValues = useReactiveVar(equipmentRepairRequestsFiltersVar);
    const {setFilters} = useEquipmentRepairRequestsFiltersVar()


    const filterChangeHandler = (filters: EquipmentRepairRequestFilterInput) => {
        setFilters({
            ...filterValues,
            ...filters
        } as EquipmentRepairRequestFilterInput);
    };


    function getAutocompleteLabel(arrayIds: any[], array: any[]) {
        const names = arrayIds ? array.filter((item) => arrayIds?.includes(item.id))
            .map((item) => `(${item.name})`) : [];

        const result = names.length > 0 ? names.length > 2 ? names.slice(0, 2).join(", ") + " и ещё " + (names.length - 2) : names.join(", ") : [];

        return (result?.length === 0) ? `` : `: ${result}`;

    }


    const filters: Filter[] = useMemo(() => {

        const sportSubZonesOptions = filterValues?.sportZoneIds?.length > 0 ? [...filteredSportSubZoneList].filter(item =>
            filterValues?.sportZoneIds?.includes(item?.sportZone?.id)
        ) : filteredSportSubZoneList;


        const filtersList: Filter[] = [
            {
                name: "sportZoneIds",
                title: "Объекты спорта",
                value: filterValues?.sportZoneIds,
                options: filteredSportZoneList
                    ?.slice()
                    ?.sort((a, b) => -b?.name.localeCompare(a?.name))
                    ?.sort((a, b) => -b.sportObject?.name.localeCompare(a.sportObject?.name)) || [],
                disableDelete: true,
                filterComponent: (props: any) => <Autocomplete
                    groupBy={(option) => option?.sportObject?.name}
                    filterResult={(result: any[]) => {
                        if (filterChangeHandler) {
                            filterChangeHandler({"sportZoneIds": result.map(val => val.id) as string[]});
                        }
                    }}
                    {...props}
                />,
                label: filterValues?.sportZoneIds ? getAutocompleteLabel(filterValues.sportZoneIds as any, filteredSportZoneList) : "",
                type: "autocomplete"
            },
            {
                name: "sportSubZoneIds",
                title: "Спортзона",
                value: filterValues?.sportSubZoneIds,
                options: sportSubZonesOptions
                    ?.slice()
                    ?.sort((a, b) => -b?.name.localeCompare(a?.name))
                    ?.sort((a, b) => -b.sportZone?.name.localeCompare(a.sportZone?.name)) || [],
                disableDelete: true,
                filterComponent: (props: any) => <Autocomplete
                    groupBy={(option) => option?.sportZone?.name}
                    filterResult={(result: any[]) => {
                        if (filterChangeHandler) {
                            filterChangeHandler({"sportSubZoneIds": result.map(val => val.id) as string[]});
                        }
                    }}
                    {...props}
                />,
                label: filterValues?.sportSubZoneIds ? getAutocompleteLabel(filterValues.sportSubZoneIds as any, sportSubZonesOptions) : "",
                type: "autocomplete"
            },
            //statuses
            {
                name: "statuses",
                title: "Статусы",
                value: filterValues?.statuses,
                filterComponent: (props: any) => <Autocomplete
                    filterResult={(result: any[]) => {
                        if (filterChangeHandler) {
                            filterChangeHandler({"statuses": result.map(val => val.id) as EquipmentRepairRequestStatusEnum[]});
                        }
                    }}
                    {...props}
                />,
                options: (Object.keys(EquipmentRepairRequestStatusEnum) as Array<keyof typeof EquipmentRepairRequestStatusEnum>)
                    .map((key) => ({
                        id: key,
                        name: getLabelStatusRepairRequest(EquipmentRepairRequestStatusEnum[key])
                    })),
                label: filterValues?.statuses ? getAutocompleteLabel(filterValues.statuses as any, (Object.keys(EquipmentRepairRequestStatusEnum) as Array<keyof typeof EquipmentRepairRequestStatusEnum>)
                    .map((key) => ({
                        id: key,
                        name: getLabelStatusRepairRequest(EquipmentRepairRequestStatusEnum[key])
                    }))) : "",
                type: "autocomplete"
            },


        ] as Filter[];

        return filtersList;
    }, [
        filterValues,
        filteredSportZoneList,
        filteredSportSubZoneList,
    ]);

    function deleteHandler(filter: Filter) {
        setFilters({
            ...filterValues,
            [filter.name]: null
        } as SportEquipmentListFilters);
    }

    function clearFiltersHandler() {
        const cleanedFilters = [...filters].reduce((a, filter) => ({...a, [filter.name]: null}), {});

        setFilters({...cleanedFilters} as SportEquipmentListFilters);
    }

    const onChangeFilterValue = (filterName: string, value: any) => {
        filterChangeHandler({[filterName]: value});
    };

    return (
        <>
            <FiltersToolbar
                filters={filters}
                onDelete={deleteHandler}
                clearFilters={clearFiltersHandler}
                onChangeFilterValue={onChangeFilterValue}
                placeholder={"Фильтры"}
            />
        </>
    );
};

export default Filters;