import React, { FC } from "react";
import {
  EquipmentRequestAcceptIntoUseInput,
  EquipmentRequestEntity, EquipmentRequestStatusEnum, UpdateEquipmentRequestInput,
  useGetAllSportCategoryDictionaryQuery
} from "../../../../graphql/graphQlApiHooks";
import {
  Alert,
  Box,
  Button, Card, CardContent,
  CardHeader,
  Checkbox, Chip,
  DialogActions, DialogContent,
  FormControlLabel,
  Link,
  ListItem, Stack, TextField, Tooltip,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { HighlightedBox } from "../../../../components/HighlightedBox";
import { useStores } from "../../../../store";
import { useSnackbar } from "notistack";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadFile } from "../../../../helpers/downloadFile";

interface Props {
  equipment: EquipmentRequestEntity;
  close: () => void;
}

const Item = (props: any) => {
  const theme = useTheme();
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      width={"100%"}
      sx={{
        padding: theme.spacing(0, 2),
        boxShadow: `0 -1px 0 0 ${theme.palette.divider} inset`
      }}
    >{
      props.children
    }</Box>
  );
};
const RejectReasonDialog: FC<Props> = (props) => {
  const { equipment, close } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { data: getAllSportCategoryDictionary } = useGetAllSportCategoryDictionaryQuery();
  const [rejectReasonInputValue, setRejectReasonInputValue] = React.useState('');
  const [rejectReasonInputValueError, setRejectReasonInputValueError] = React.useState('');

  const { equipmentRequestStore } = useStores();

  const handleSubmit = () => {
    let input: UpdateEquipmentRequestInput;
    if (rejectReasonInputValue === '') {
      enqueueSnackbar('Причина отклонения должна быть указана', {
        variant: "error"
      });
      setRejectReasonInputValueError('Причина отклонения должна быть указана');
      return;
    }

    if (equipment) {
      input = {
        id: equipment?.id,
        name: equipment?.name,
        count: Number(equipment?.count),
        equipmentTypeId: equipment?.equipmentTypeObject?.id as string,
        requestStatus: EquipmentRequestStatusEnum.Rejected,
        rejectReason: rejectReasonInputValue,
        sportObjectId: equipment?.sportObject.id,
        serviceNoteScanId: equipment?.serviceNoteScan?.id,
      }

      equipmentRequestStore.update(input).then(() => {
          setRejectReasonInputValue('')
          close();
        })
    }
  };


  return (
    <>
      <DialogContent
        dividers
        sx={{
          bgcolor: "background.default"
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          spacing={4}
        >
          <Stack
            direction={"column"}
            spacing={2}
          >

            <Card
              sx={{
                minWidth: 270
              }}
            >
              <CardHeader
                title={
                  <Typography
                    variant='h6'
                    color='text.primary'
                    sx={{ width: "100%" }}
                  >
                    Ожидаемое расположение оборудования
                  </Typography>

                }
              />
              <CardContent
                sx={{
                  padding: 0
                }}
              >
                {
                  [
                    { title: "Спортобъект:", value: equipment?.sportObject?.name },
                    { title: "Объект спорта:", value: equipment?.sportZone?.name },
                    { title: "Спортзона:", value: equipment?.sportSubZone?.name }
                  ].map((description: any, index: number) => {
                    if (!description.value) return null;
                    return (
                      <Item key={index}>
                        <ListItem
                          component={"div"}
                          sx={{
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                            alignContent: "start"

                          }}
                        >
                          <Typography
                            variant='body2'
                            color='text.primary'
                            sx={{ width: "100%" }}
                          >
                            {description.title}
                          </Typography>
                          <Typography
                            variant='h6'
                            color='text.primary'
                            sx={{ width: "100%" }}
                          >
                            {description.value}
                          </Typography>
                        </ListItem>
                      </Item>
                    );
                  })}
              </CardContent>
            </Card>
            <Card
              sx={{
                minWidth: 270
              }}
            >
              <CardHeader
                title={
                  <Typography
                    variant='h6'
                    color='text.primary'
                    sx={{ width: "100%" }}
                  >
                    Категория спорта
                  </Typography>

                }
              />
              <CardContent
                sx={{
                  padding: 0
                }}
              >
                {
                  [
                    {
                      title: "Категория спорта:",
                      value: getAllSportCategoryDictionary?.getAllSportCategoryDictionary?.find((item) => item.id === equipment?.sportKindObject?.sportCategoryId)?.name
                    },
                    {
                      title: "Вид спорта:",
                      value: equipment?.sportKindObject?.name
                    },
                    {
                      title: "Тип оборудования:",
                      value: equipment?.equipmentTypeObject?.name
                    }
                  ].map((description: any, index: number) => {
                    if (!description.value) return null;
                    return (
                      <Item key={index}>
                        <ListItem
                          component={"div"}
                          sx={{
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: "start",
                            alignContent: "start"

                          }}
                        >
                          <Typography
                            variant='body2'
                            color='text.primary'
                            sx={{ width: "100%" }}
                          >
                            {description.title}
                          </Typography>
                          <Typography
                            variant='h6'
                            color='text.primary'
                            sx={{ width: "100%" }}
                          >
                            {description.value}
                          </Typography>
                        </ListItem>
                      </Item>
                    );
                  })}
              </CardContent>
            </Card>

          </Stack>

          <Stack
            direction={"column"}
            spacing={2}
          >
            <Card
              sx={{
                minWidth: 420
              }}
            >
              <Item>
                <CardHeader
                  mb={2}
                  title={`${equipment?.name.charAt(0).toUpperCase()}${equipment?.name.slice(1)}`}
                  subheader={
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      spacing={2}
                      width={"100%"}
                      alignItems={"center"}
                    >
                      <Typography
                        variant='caption'
                        color='text.primary'
                        sx={{ width: "100%" }}
                      >
                        Статус:
                      </Typography>
                      <Typography
                        variant='body2'
                        color='text.primary'
                        sx={{ width: "100%" }}
                        whiteSpace={"nowrap"}
                      >
                        {equipmentRequestStore.getStatusName(equipment?.requestStatus)}
                      </Typography>
                    </Stack>
                  }

                />
              </Item>
              <Item>
                <Box
                  flexDirection={"column"}
                  py={2}
                  px={2}
                  width={"100%"}
                >
                  <Typography
                    component={"div"}
                    variant='h6'
                    color='text.secondary'
                    sx={{ width: "100%", mb: 1 }}
                  >
                    Дополнительная информация
                  </Typography>
                  <HighlightedBox sx={{ p: 2, borderRadius: 2 }}>
                    <Typography
                      component={"div"}
                      variant='body1'
                      color='text.primary'
                      sx={{ width: "100%" }}
                    >
                      {equipment?.description ? `${equipment?.description?.charAt(0).toUpperCase()}${equipment?.description?.slice(1)}` : "Не указана"}
                    </Typography>
                  </HighlightedBox>
                </Box>
              </Item>
              {
                [
                  {
                    title: "Срочно:",
                    value: equipment?.urgentRequirementComment ? <Alert severity='info'>{equipment?.urgentRequirementComment}</Alert> : undefined
                  },
                  {
                    title: "Количество:",
                    value: `${equipment?.count} ${equipment?.unitOfMeasurement ? equipment?.unitOfMeasurement?.name : ""}`
                  },
                  {
                    title: "Цена:",
                    value: equipment?.price ? `${equipment?.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} руб.` : undefined
                  },
                  {
                    title: "Ссылка на аналогичное оборудование:",
                    value: equipment?.analogEquipmentLink ? <Link
                      href={equipment?.analogEquipmentLink}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Аналогичное оборудование
                    </Link> : undefined
                  },
                  {
                    title: "Ссылка на оборудование:",
                    value: equipment?.equipmentLink ? <Link
                      href={equipment?.equipmentLink}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Оборудование
                    </Link> : undefined
                  },
                  {
                    title: "Скан служебной записки с потребностью:",
                    value: equipment?.serviceNoteScan ? <Tooltip
                        title={"Скачать служебную записку с потребностью"}
                      >
                        <Chip
                          label={equipment?.serviceNoteScan?.name}
                          color='primary'
                          variant='outlined'
                          onClick={() => downloadFile(equipment?.serviceNoteScan)}
                          icon={<DownloadIcon
                            sx={{
                              width: 16
                            }}
                          />}
                        />
                      </Tooltip>
                      : undefined
                  }
                ].map((description: any, index: number) => {
                  if (!description.value) return null;
                  return (
                    <Item key={index}>
                      <ListItem component={"div"}>
                        <Typography
                          variant='body2'
                          color='text.primary'
                          sx={{ width: "100%" }}
                        >
                          {description.title}
                        </Typography>
                        <Typography
                          variant='h6'
                          color='text.primary'
                          align={"right"}
                          sx={{ width: "100%" }}
                        >
                          {description.value}
                        </Typography>
                      </ListItem>
                    </Item>
                  );
                })}


            </Card>
            <Card>
              <CardContent>

                <TextField
                  value={rejectReasonInputValue}
                  onChange={(event) => {
                    setRejectReasonInputValue(event.target.value);
                    if (event.target.value.length > 0) {
                      setRejectReasonInputValueError('');
                    } else {
                      setRejectReasonInputValueError('Причина отклонения должна быть указана');
                    }
                  }}
                  placeholder={'Укажите причину отклонения'}
                  label={"Причина отклонения"}
                  multiline
                  fullWidth
                  required={true}
                  autoFocus
                  error={rejectReasonInputValueError.length > 0}
                  helperText={rejectReasonInputValueError}
                  sx={{
                    height: 120,
                    '& .MuiInputBase-root': {
                      height: '100%',
                      '& textarea': {
                        alignSelf: 'start'
                      }
                    }
                  }}
                />

              </CardContent>
            </Card>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={close}
          color='secondary'
          size='small'
        >
          Отмена
        </Button>
        <Button
          onClick={handleSubmit}
          color='primary'
          variant='outlined'
          sx={{ py: .5, px: 2, mr: 2 }}
        >
          Отклонить заявку
        </Button>


      </DialogActions>

    </>
  );
};

export default RejectReasonDialog;