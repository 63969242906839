import React, {useEffect, useState} from 'react';
import {EquipmentRepairRequestEntity, useEquipmentRepairRequestsQuery} from "../../../../graphql/graphQlApiHooks";
import List from "./List";
import Settings from "../../../../store/settings";
import {equipmentRepairRequestsFiltersVar} from "../../../../store/reactiveVarsStores/repair-requests/filters";
import {
    RepairRequestsListSortOrder,
    repairRequestsListSortOrderVar,
    useRepairRequestsListSortOrderVar
} from "../../../../store/reactiveVarsStores/repair-requests/repairRequestsListSortOrder";
import {
    RepairRequestsListPagination,
    repairRequestsListPaginationVars,
    useRepairRequestsListPaginationVars
} from "../../../../store/reactiveVarsStores/repair-requests/repairRequestsListPagination";
import {useReactiveVar} from "@apollo/client";
import {sportObjectsVars} from "../../../../store/reactiveVarsStores/sport-objects/sportObjects";
import {useEquipmentRepairRequestsTotalCountVar} from "../../../../store/reactiveVarsStores/repair-requests/totalCount";

interface Props {

}

const STATE_INITIAL = {
    isEquipmentRepairRequestsLoading: true,
    equipmentRepairRequests: [],
    allCount: 0
};
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

    const pagination = useReactiveVar(repairRequestsListPaginationVars);
    const filters = useReactiveVar(equipmentRepairRequestsFiltersVar);

    const {setPagination} = useRepairRequestsListPaginationVars();
    const {setOrder} = useRepairRequestsListSortOrderVar();
    const {setTotalCount} = useEquipmentRepairRequestsTotalCountVar();
    const sortOrderModel = useReactiveVar(repairRequestsListSortOrderVar);


    const {selectedSportObjectIds, filteredSportZoneList, filteredSportSubZoneList} = useReactiveVar(sportObjectsVars);


    const {data: equipmentRepairRequestsConnection, loading, refetch} = useEquipmentRepairRequestsQuery({
        variables: {
            filter: {
                // текущие значения фильтров
                ...filters,
                sportObjectIds: selectedSportObjectIds,
                sportZoneIds: !filters.sportZoneIds ? filteredSportZoneList.map(o => o.id) : filters.sportZoneIds,
                sportSubZoneIds: !filters.sportSubZoneIds ? filteredSportSubZoneList.map(o => o.id) : filters.sportSubZoneIds,
                // текущие значения пагинации
                skip: pagination?.page * pagination?.pageSize,
                take: pagination?.pageSize
            },
        },
        pollInterval: Settings.pollIntervals.sportEquipments
    });

    useEffect(() => {
        refetch({
            filter: {
                // текущие значения фильтров
                ...filters,
                sportObjectIds: selectedSportObjectIds,
                sportZoneIds: !filters.sportZoneIds ? filteredSportZoneList.map(o => o.id) : filters.sportZoneIds,
                sportSubZoneIds: !filters.sportSubZoneIds ? filteredSportSubZoneList.map(o => o.id) : filters.sportSubZoneIds,
                // текущие значения пагинации
                skip: pagination?.page * pagination?.pageSize,
                take: pagination?.pageSize
            },
        })
    }, [pagination, filters, selectedSportObjectIds]);

    const [state, setState] = useState<
        {
            isEquipmentRepairRequestsLoading: boolean
            equipmentRepairRequests: EquipmentRepairRequestEntity[]
            allCount: number
        }
    >(STATE_INITIAL);


    useEffect(() => {


        setState((prev) => ({
            ...prev,
            isEquipmentRepairRequestsLoading: !equipmentRepairRequestsConnection
        }));

        if (equipmentRepairRequestsConnection?.equipmentRepairRequests?.equipmentRepairRequests) {
            setState((prev) => ({
                ...prev,
                equipmentRepairRequests: equipmentRepairRequestsConnection.equipmentRepairRequests.equipmentRepairRequests as EquipmentRepairRequestEntity[]
            }));
        }

        if (equipmentRepairRequestsConnection?.equipmentRepairRequests?.total) {
            setTotalCount(equipmentRepairRequestsConnection.equipmentRepairRequests.total);
            setState((prev) => ({
                ...prev,
                allCount: equipmentRepairRequestsConnection.equipmentRepairRequests.total
            }));
        }

    }, [equipmentRepairRequestsConnection]);


    return (
        <List
            equipmentRepairRequest={state.equipmentRepairRequests as EquipmentRepairRequestEntity[]}

            pagination={pagination as RepairRequestsListPagination}
            sortOrderModel={sortOrderModel as RepairRequestsListSortOrder}


            allCount={state.allCount}

            loading={state.isEquipmentRepairRequestsLoading}

            actions={{
                setPagination,
                setOrder
            }}
        />
    );
};
