import React, {FC, useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputAdornment,
    OutlinedInputProps,
    Stack,
    TextField,
    Tooltip
} from "@mui/material";
import {
    CreateEquipmentRequestInput,
    DictionaryEntity,
    DictionaryTypesEnum,
    EquipmentTypeDictionaryEntity,
    FileStoreEntity,
    SportKindDictionaryEntity,
    SportObjectEntity,
    SportSubZoneEntity,
    SportZoneEntity,
    useGetFullDictionaryQuery
} from "graphql/graphQlApiHooks";
import {useStores} from "store";
import Dialog from "components/Dialogs/Dialog";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UploaderService from "../../../../../services/UplodaService";
import {omitBy} from "lodash";
import {EquipmentRequestStatusEnum} from "../../../../../store/stores/equipment-request-status.enum";
import {isValidUrl} from "../../../../../helpers/validateUrl";
import {downloadFile} from "../../../../../helpers/downloadFile";

interface IEquipmentRequestCreateDialogProps {
    close: (close: boolean) => void;
    open: boolean;
}

/**
 Начальное состояние для валидации формы.
 */
const getInitialErrors = () => ({
    sportObject: undefined as string | undefined,
    sportZone: undefined as string | undefined,
    sportSubZone: undefined as string | undefined,
    name: undefined as string | undefined,
    sportKind: undefined as string | undefined,
    equipmentType: undefined as string | undefined,
    equipmentLink: undefined as string | undefined,
    analogEquipmentLink: undefined as string | undefined,
    urgentRequirementComment: undefined as string | undefined,
    serviceNoteScanId: undefined as string | undefined
});

/**
 * Начальное состояние для мутации создания заявки на оборудование.
 */
const getInitialInput = (): CreateEquipmentRequestInput => ({
    sportObjectId: "",
    sportZoneId: "",
    sportSubZoneId: "",
    equipmentTypeId: "",
    sportKindId: "",
    serviceNoteScanId: "",
    unitOfMeasurementId: "",
    name: "",
    count: 1,
    description: "",
    urgentRequirementComment: "",
    equipmentLink: "",
    mandatorySpecifications: "",
    price: 0,
    analogEquipmentLink: "",
    requestStatus: EquipmentRequestStatusEnum.Submitted
});

/**
 * Диалог создания заявки на оборудование.
 */
export const EquipmentRequestCreateDialog: FC<IEquipmentRequestCreateDialogProps> = (props) => {
    const {equipmentRequestStore, sportZonesStore, sportObjectsStore, userStore, dictionariesStore} = useStores();

    // Единицы измерения
    const {data: {dictionaries: measurementUnits = []} = {}} = useGetFullDictionaryQuery({
        variables: {dictionaryType: DictionaryTypesEnum.MeasurementUnit}
    });
    // Спортобъекты
    const [sportObjects_a1, setSportObjects_a1] = useState<SportObjectEntity[]>([]);
    const [sportObject_a1, setSportObject_a1] = useState<SportObjectEntity | null>(null);
    // Объекты спорта
    const [sportZones_a2, setSportZones_a2] = useState<SportZoneEntity[]>([]);
    const [sportZone_a2, setSportZone_a2] = useState<SportZoneEntity | null>(null);
    // Спортзоны
    const [sportSubZones_a3, setSportSubZones_a3] = useState<SportSubZoneEntity[]>([]);
    const [sportSubZone_a3, setSportSubZone_a3] = useState<SportSubZoneEntity | null>(null);
    // Виды спорта
    const [sportKinds_b1, setSportKinds_b1] = useState<SportKindDictionaryEntity[]>([]);
    const [sportKind_b1, setSportKind_b1] = useState<SportKindDictionaryEntity | null>(null);
    // Тип оборудования
    const [equipmentTypes_b2, setEquipmentTypes_b2] = useState<EquipmentTypeDictionaryEntity[]>([]);
    const [equipmentType_b2, setEquipmentType_b2] = useState<EquipmentTypeDictionaryEntity | null>(null);
    // Единица измерения
    const [unitOfMeasurement, setUnitOfMeasurement] = useState<DictionaryEntity | null>(null);
    // Флаг срочной потребности
    const [isCriticallyNeed, setIsCriticallyNeed] = useState(false);
    // Скан служебной записки с потребностью
    const [serviceNoteScan, setServiceNoteScan] = useState<FileStoreEntity | null>(null);
    const serviceNoteScanInputRef = React.useRef<HTMLInputElement>(null);
    // Input для мутации создания
    const [input, setInput] = useState<CreateEquipmentRequestInput>(getInitialInput);
    // Ошибки валидации
    const [errors, setErrors] = useState(getInitialErrors);

    useEffect(() => {
        if (sportObjectsStore.sportObjects) {
            setSportObjects_a1(sportObjectsStore.sportObjects as SportObjectEntity[]);
        }
    }, [sportObjectsStore.sportObjects, props.open]);

    useEffect(() => {
        if (dictionariesStore.sportKindDictionary) {
            setSportKinds_b1(dictionariesStore.sportKindDictionary as SportKindDictionaryEntity[]);
        }
    }, [dictionariesStore.sportKindDictionary, props.open]);

    /* ====================================== */
    /* ========== Действия диалога ========== */
    /* ====================================== */

    const onSubmit = async () => {
        const cleanedInput = omitBy(input, el => el === "") as CreateEquipmentRequestInput;
        const nextErrors: typeof errors = {
            name: !cleanedInput.name?.trim()
                ? "Вы не указали название оборудования" : undefined,

            sportObject: !cleanedInput.sportObjectId
                ? "Вы не выбрали спортобъект" : undefined,
            sportZone: !cleanedInput.sportZoneId
                ? "Вы не выбрали объект спорта" : undefined,
            sportSubZone: !cleanedInput.sportSubZoneId
                ? "Вы не выбрали спортзону" : undefined,

            sportKind: !cleanedInput.sportKindId
                ? "Вы не выбрали вид спорта" : undefined,
            equipmentType: !cleanedInput.equipmentTypeId
                ? "Вы не выбрали тип оборудования" : undefined,

            serviceNoteScanId: !cleanedInput.serviceNoteScanId
                ? "Вы не загрузили скан служебной записки" : undefined,

            urgentRequirementComment: isCriticallyNeed && !cleanedInput.urgentRequirementComment?.trim()
                ? "Вы не указали причину срочной потребности" : undefined,
            equipmentLink: cleanedInput.equipmentLink?.trim() && !isValidUrl(cleanedInput.equipmentLink?.trim())
                ? "Ссылка на оборудование должна быть валидным URL" : undefined,
            analogEquipmentLink: cleanedInput.analogEquipmentLink?.trim() && !isValidUrl(cleanedInput.analogEquipmentLink?.trim())
                ? "Ссылка на аналоги оборудования должна быть валидным URL" : undefined
        };
        setErrors(nextErrors);
        if (Object.values(nextErrors).every((error) => !error)) {
            await equipmentRequestStore.create(cleanedInput).then(() => handleCloseModal());
        }
    };

    const handleCloseModal = () => {
        setSportObjects_a1([]);
        setSportObject_a1(null);
        setSportZones_a2([]);
        setSportZone_a2(null);
        setSportSubZones_a3([]);
        setSportSubZone_a3(null);
        setSportKinds_b1([]);
        setSportKind_b1(null);
        setEquipmentTypes_b2([]);
        setEquipmentType_b2(null);
        setServiceNoteScan(null);
        setIsCriticallyNeed(false);
        setUnitOfMeasurement(null);
        setInput(getInitialInput);
        setErrors(getInitialErrors);
        props.close(false);
    };

    /* ======================================== */
    /* ========== Хэндлеры изменений ========== */
    /* ======================================== */

    // Изменение Спортобъекта
    const onChangeSportObjectHandler = (value: SportObjectEntity) => {
        setSportObject_a1(value);
        if (value && value?.sportZones) {
            setInput({...input, sportObjectId: value.id});
            setSportZones_a2(value.sportZones);
        }
    };
    // Изменение Объекта спорта
    const onChangeSportZoneHandler = (sportZone: SportZoneEntity | null) => {
        sportZone?.id && setInput({...input, sportZoneId: sportZone.id as string});
        setSportZone_a2(sportZone);
        if (sportZone?.sportSubZones) {
            setSportSubZones_a3(sportZone.sportSubZones);
            if (sportZone.sportSubZones.length === 0) {
                setSportSubZone_a3(null);
            }
        } else {
            setSportSubZones_a3([]);
        }
        setSportSubZone_a3(null);
    };
    // Изменение Спортзоны
    const onChangeSportSubZoneHandler = (sportSubZone: SportSubZoneEntity | null) => {
        sportSubZone?.id && setInput({...input, sportSubZoneId: sportSubZone.id as string});
        setSportSubZone_a3(sportSubZone);
    };
    // Изменение Вида спорта
    const onChangeSportKindHandler = (sportKind: SportKindDictionaryEntity | null) => {
        setSportKind_b1(sportKind);
        if (sportKind && sportKind?.equipmentTypes) {
            setInput({...input, sportKindId: sportKind.id as string});
            setEquipmentTypes_b2(sportKind.equipmentTypes as EquipmentTypeDictionaryEntity[]);
        } else {
            setInput({...input, sportKindId: ""});
            setEquipmentTypes_b2([]);
        }
        setEquipmentType_b2(null);
    };
    // Изменение Типа оборудования
    const onChangeEquipmentTypeHandler = (equipmentType: EquipmentTypeDictionaryEntity | null) => {
        setEquipmentType_b2(equipmentType);
        if (equipmentType?.sportKind && equipmentType?.sportKind?.id !== sportKind_b1?.id) {
            setSportKind_b1(equipmentType?.sportKind);
            const sportKind = sportKinds_b1.find((kind: SportKindDictionaryEntity) => kind.id === equipmentType?.sportKind?.id);
            if (sportKind && sportKind?.equipmentTypes) {
                setInput({...input, sportKindId: sportKind.id as string});
                setEquipmentTypes_b2(sportKind?.equipmentTypes as EquipmentTypeDictionaryEntity[]);
            }
        }
        if (equipmentType && equipmentType?.equipmentSpecifications) {
            setInput({...input, equipmentTypeId: equipmentType.id as string});
        }
    };
    // Изменение единицы измерения
    const onChangeUnitOfMeasurement = (unit?: DictionaryEntity | null) => {
        setUnitOfMeasurement(unit);
        setInput({...input, unitOfMeasurementId: unit.id || ""});
    };
    // Изменение количества
    const onClickCountUp = () => setInput({...input, count: input.count + 1});
    const onClickCountDown = () => setInput({...input, count: Math.max(1, input.count - 1)});
    const onChangeCount = (event: React.ChangeEvent<HTMLInputElement>) => setInput({
        ...input,
        count: Math.max(1, Number(event.target.value))
    });
    // Изменение цены
    const onChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => setInput({
        ...input,
        price: Math.max(0, Number(event.target.value))
    });

    /* ========================= */
    /* ========== JSX ========== */
    /* ========================= */

    return (
        <Dialog
            title={"Заявка на новое оборудование"}
            open={props.open}
            onClose={handleCloseModal}
            props={{
                "aria-labelledby": "scroll-dialog-title",
                "aria-describedby": "scroll-dialog-description",
                maxWidth: "md"
                // classes: {paper: classesUpload.root,}
            }}
            DialogContentProps={{
                sx: {
                    bgcolor: "background.default"
                }
            }}
            actions={<>
                <Button
                    type='submit'
                    color='primary'
                    variant='contained'
                    form='addEquipmentRequest'
                    onClick={onSubmit}
                >
                    Создать заявку
                </Button>
            </>}
            titles={[
                <Stack
                    gap={2}
                    flexDirection='row'
                    width='100%'
                    my={3}
                    mx={2}
                >
                    <TextField
                        /* ======================================================== */
                        /* ========== Название необходимого оборудования ========== */
                        /* ======================================================== */
                        required
                        value={input.name}
                        onChange={(event) => setInput({...input, name: event.target.value})}
                        id='equipmentName'
                        label='Название необходимого оборудования'
                        sx={{flexGrow: 1}}
                        placeholder='Укажите название'
                        InputLabelProps={{shrink: true}}
                        error={!!errors.name}
                        helperText={errors.name}
                        InputProps={{
                            sx: {
                                fontWeight: 600,
                            }
                        }}
                    />
                    <Autocomplete
                        /* ======================================= */
                        /* ========== Единица измерения ========== */
                        /* ======================================= */
                        options={measurementUnits}
                        getOptionLabel={(option?: DictionaryEntity) => option?.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={unitOfMeasurement}
                        onChange={(event, value?: DictionaryEntity) => onChangeUnitOfMeasurement(value)}
                        sx={{width: "23%"}}
                        renderInput={params => <TextField
                            {...params}
                            label='Единица измерения'
                        />}
                        placeholder='Единица измерения'
                    />
                    <TextField
                        /* ================================ */
                        /* ========== Количество ========== */
                        /* ================================ */
                        required
                        sx={{width: "15%", ".MuiInputBase-input": {textAlign: "center"}}}
                        id='equipmentCount'
                        value={input.count}
                        label='Количество'
                        onChange={onChangeCount}
                        type={"number"}
                        variant='outlined'
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <IconButton
                                    onClick={onClickCountUp}
                                    edge='start'
                                    size='small'
                                > <AddIcon fontSize='small'/> </IconButton>
                            </InputAdornment>,
                            startAdornment: <InputAdornment position='start'>
                                <IconButton
                                    onClick={onClickCountDown}
                                    edge='end'
                                    size='small'
                                > <RemoveIcon fontSize='small'/></IconButton>
                            </InputAdornment>
                        } as Partial<OutlinedInputProps>}
                        InputLabelProps={{shrink: true}}
                    />
                </Stack>
            ]}
        >
            <Stack
                gap={4}
                direction='column'
            >

                <Card>
                    <CardContent>
                        <Stack
                            direction='row'
                            spacing={2}
                        >
                            {((userStore?.currentUser?.user?.sportObjects && userStore?.currentUser?.user?.sportObjects?.length > 0) || !userStore?.currentUser?.user?.isForSportObject) &&
                                <Autocomplete
                                    /* ======================================================== */
                                    /* =================== Спортобъект ======================== */
                                    /* ======================================================== */
                                    value={sportObject_a1}
                                    id='sportObject'
                                    fullWidth
                                    options={sportObjects_a1}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    onChange={(e, value: SportObjectEntity) => onChangeSportObjectHandler(value)}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        required
                                        error={!!errors.sportObject}
                                        helperText={errors.sportObject}
                                        label='Спортобъект'
                                        variant='outlined'
                                        placeholder='Выберите спортобъект'
                                        size='small'
                                        InputLabelProps={{shrink: true}}
                                    />}
                                />
                            }
                            <Autocomplete
                                /* =================================== */
                                /* ========== Объект спорта ========== */
                                /* =================================== */
                                value={sportZone_a2}
                                id='sportZone'
                                fullWidth
                                disabled={sportZones_a2.length === 0}
                                options={sportZones_a2}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                onChange={(e, value: any) => onChangeSportZoneHandler(value)}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label='Объект спорта'
                                    variant='outlined'
                                    size={"small"}
                                    placeholder='Выберите объект спорта'
                                    InputLabelProps={{shrink: true}}
                                    error={!!errors.sportZone}
                                    helperText={errors.sportZone}
                                />}
                            />
                            <Autocomplete
                                /* =============================== */
                                /* ========== Спортзона ========== */
                                /* =============================== */
                                value={sportSubZone_a3}
                                id='sportZone'
                                fullWidth
                                disabled={sportSubZones_a3.length === 0}
                                options={sportSubZones_a3}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                onChange={(e, value: any) => onChangeSportSubZoneHandler(value)}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label='Спортзона'
                                    variant='outlined'
                                    placeholder='Выберите спортзону'
                                    size={"small"}
                                    InputLabelProps={{shrink: true}}
                                    error={!!errors.sportSubZone}
                                    helperText={errors.sportSubZone}
                                />}
                            />
                        </Stack>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Stack
                            direction='row'
                            spacing={2}
                        >
                            <Autocomplete
                                /* ================================ */
                                /* ========== Вид спорта ========== */
                                /* ================================ */
                                value={sportKind_b1}
                                disabled={dictionariesStore.sportKindDictionary.length === 0}
                                id='sportKind'
                                fullWidth
                                options={sportKinds_b1}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                onChange={(e, value: any) => onChangeSportKindHandler(value)}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label='Вид спорта *'
                                    variant='outlined'
                                    placeholder='Выберите вид спорта'
                                    size={"small"}
                                    InputLabelProps={{shrink: true}}
                                    error={!!errors.sportKind}
                                    helperText={errors.sportKind}
                                />}
                            />
                            <Autocomplete
                                /* ====================================== */
                                /* ========== Тип оборудования ========== */
                                /* ====================================== */
                                value={equipmentType_b2}
                                disabled={sportKind_b1?.equipmentTypes?.length === 0}
                                id='equipmentType'
                                fullWidth
                                options={equipmentTypes_b2.length > 0 ? equipmentTypes_b2 : dictionariesStore?.equipmentTypeDictionary}
                                getOptionLabel={(option) => option.name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                groupBy={(option: any) => (option?.sportKind) ? option?.sportKind.name : undefined}
                                onChange={(e, value: EquipmentTypeDictionaryEntity | null) => {
                                    onChangeEquipmentTypeHandler(value);
                                }}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label='Тип оборудования *'
                                    variant='outlined'
                                    placeholder='Выберите тип оборудования'
                                    size={"small"}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    error={!!errors.equipmentType}
                                    helperText={errors.equipmentType}
                                />}
                            />
                        </Stack>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Stack
                            direction='column'
                            gap={4}
                        >
                            <Stack
                                direction='row'
                                spacing={2}
                            >
                                <TextField
                                    /* ============================================ */
                                    /* ========== Ссылка на оборудование ========== */
                                    /* ============================================ */
                                    value={input.equipmentLink}
                                    onChange={e => setInput({...input, equipmentLink: e.target.value})}
                                    fullWidth
                                    size={"small"}
                                    label='Ссылка на оборудование'
                                    placeholder='Ссылка на оборудование'
                                    error={!!errors.equipmentLink}
                                    helperText={errors.equipmentLink}
                                    variant='outlined'
                                    InputLabelProps={{shrink: true}}
                                />
                                <TextField
                                    /* =================================================== */
                                    /* ========== Ссылка на аналог оборудования ========== */
                                    /* =================================================== */
                                    value={input.analogEquipmentLink}
                                    onChange={e => setInput({...input, analogEquipmentLink: e.target.value})}
                                    fullWidth
                                    variant='outlined'
                                    label='Ссылка на аналог оборудования'
                                    placeholder='Ссылка на аналог оборудования'
                                    size='small'
                                    error={!!errors.analogEquipmentLink}
                                    helperText={errors.analogEquipmentLink}
                                    InputLabelProps={{shrink: true}}
                                />
                                <TextField
                                    /* =========================================================== */
                                    /* ========== Скан служебной записки с потребностью ========== */
                                    /* =========================================================== */
                                    fullWidth
                                    variant='outlined'
                                    label='Скан служебной записки с потребностью'
                                    placeholder='Загрузите файл'
                                    value={serviceNoteScan?.name || ""}
                                    size='small'
                                    InputLabelProps={{shrink: true}}
                                    required={true}

                                    error={!!errors.serviceNoteScanId}
                                    helperText={errors.serviceNoteScanId}

                                    InputProps={{
                                        required: true,
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <Tooltip title='Загрузить'>
                                                    <IconButton
                                                        size='small'
                                                        onClick={() => serviceNoteScanInputRef.current?.click()}
                                                    >
                                                        <UploadFileIcon fontSize='small'/>
                                                    </IconButton>
                                                </Tooltip>
                                                {serviceNoteScan && <Tooltip title='Скачать'>
                                                    <IconButton
                                                        size='small'
                                                        onClick={() => downloadFile(serviceNoteScan)}
                                                    >
                                                        <DownloadIcon fontSize='small'/>
                                                    </IconButton>
                                                </Tooltip>}
                                                {serviceNoteScan && <Tooltip title='Удалить'>
                                                    <IconButton
                                                        size='small'
                                                        onClick={() => {
                                                            input.serviceNoteScanId = "";
                                                            setServiceNoteScan(null);
                                                        }}
                                                    >
                                                        <DeleteForeverIcon fontSize='small'/>
                                                    </IconButton>
                                                </Tooltip>}
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <input
                                    ref={serviceNoteScanInputRef}
                                    type='file'
                                    hidden
                                    onChange={e => UploaderService.uploadFile(e).then(file => {
                                        input.serviceNoteScanId = file.id;
                                        setServiceNoteScan(file);
                                    })}
                                />
                            </Stack>
                            <Stack
                                direction='row'
                                spacing={2}
                            >
                                <TextField
                                    /* ================================================= */
                                    /* ========== Обязательные характеристики ========== */
                                    /* ================================================= */
                                    value={input.mandatorySpecifications}
                                    onChange={e => setInput({...input, mandatorySpecifications: e.target.value})}
                                    fullWidth
                                    variant='outlined'
                                    label='Обязательные характеристики'
                                    placeholder='Обязательные характеристики'
                                    size='small'
                                    InputLabelProps={{shrink: true}}
                                />
                                <TextField
                                    /* ========================== */
                                    /* ========== Цена ========== */
                                    /* ========================== */
                                    sx={{width: "25%"}}
                                    variant='outlined'
                                    label='Цена'
                                    value={input.price}
                                    onChange={onChangePrice}
                                    InputLabelProps={{shrink: true}}
                                    type='number'
                                    placeholder='Цена'
                                    size='small'
                                />
                            </Stack>
                            <Stack
                                direction='row'
                                spacing={1}
                                alignItems='center'
                            >
                                <TextField
                                    /* =========================================== */
                                    /* =========== Срочная потребность =========== */
                                    /* =========================================== */
                                    fullWidth
                                    InputLabelProps={{shrink: true}}
                                    size='medium'
                                    value={input.urgentRequirementComment}
                                    onChange={(e) => setInput({...input, urgentRequirementComment: e.target.value})}
                                    required={isCriticallyNeed}
                                    label='Срочная потребность'
                                    placeholder='Почему оборудование необходимо срочно?'
                                    InputProps={{
                                        disabled: !isCriticallyNeed,
                                        startAdornment: (
                                            <FormControlLabel
                                                label='Срочно'
                                                control={
                                                    <Checkbox
                                                        checked={isCriticallyNeed}
                                                        color='primary'
                                                        size='small'
                                                        onChange={e => {
                                                            setIsCriticallyNeed(e.currentTarget.checked);
                                                            !e.currentTarget.checked && setInput({
                                                                ...input,
                                                                urgentRequirementComment: ""
                                                            });
                                                        }}
                                                    />
                                                }
                                            />
                                        )
                                    }}
                                />
                            </Stack>
                            <TextField
                                /* ========================================================= */
                                /* ========== Дополнительная информация по заявке ========== */
                                /* ========================================================= */
                                value={input.description}
                                onChange={(e) => setInput({...input, description: e.target.value})}
                                id='equipmentDescription'
                                label='Дополнительная информация по заявке'
                                placeholder='Укажите дополнительную информацию к заявке'
                                multiline
                                rows={7}
                                fullWidth
                                variant='outlined'
                                size={"small"}
                                InputLabelProps={{shrink: true}}
                            />
                        </Stack>
                    </CardContent>
                </Card>

            </Stack>
        </Dialog>
    );
};
