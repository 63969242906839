import React from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { GridSelectedRowCount } from "@mui/x-data-grid/components/GridSelectedRowCount";
import { GridPagination } from "@mui/x-data-grid";

interface Props {
  selected: any;
  onTakeToWorkHandler: () => void;
  isDisabled: boolean;
  selectedAvailable: number;
}


export const Footer = (props: Props) => {

  return (
    <>
      <Box sx={{ px: 3, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Box display={"flex"}>

          {props.selected.length > 0 ? <>
            <GridSelectedRowCount selectedRowCount={props.selected.length} />
            <Tooltip
              title={`Принять в работу можно только заявки со статусом 'На рассмотрении', для принятия в работу доступно ${props.selectedAvailable} заявок`}
            >
              <Box>
                <Button
                  color={"primary"}
                  variant={"outlined"}
                  size={"small"}
                  sx={{ py: .5, px: 2, mr: 2 }}
                  onClick={props.onTakeToWorkHandler}
                  disabled={props.isDisabled}
                >Принять в работу</Button>
              </Box>
            </Tooltip>
          </> : null}
        </Box>
        {
          // @ts-ignore
          <GridPagination />
        }
      </Box>
    </>
  );
};
